<script setup>
import { watchEffect } from 'vue';
const props = defineProps({
	isVisible: Boolean,
	isForm: Boolean,
	isReview: Boolean,
});


const emit = defineEmits(['update:isVisible']);

const close = () => {
	emit('update:isVisible', false);
};

const closeForm = () => {
	emit('update:isForm', false);
};

const closeReview = () => {
	emit('update:isReview', false);
};


watchEffect(() => {
	if (props.isVisible || props.isForm || props.isReview) {
		document.body.classList.add('body-no-scroll');
	} else {
		document.body.classList.remove('body-no-scroll');
	}
});
</script>

<template>

<div v-if="isVisible" class="modal" @click="close">
	<div v-if="isVisible" class="modal-content" @click.stop>
		<slot></slot>
		<button @click="close" class="modal-content__button">OK</button>
	</div>
</div>
<div v-if="isForm" class="modal" @click="closeForm">
	<div class="modal-content-form" @click.stop>
	<slot></slot>
	</div>
</div>
<div v-if="isReview" class="modal" @click="closeReview">
	<div class="modal-content-review" @click.stop>
		<slot></slot>
	</div>
</div>
</template>



<style lang="scss" scoped>
.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(54, 53, 53, 0.9);
	display: flex;
	z-index: 4;
	justify-content: center;
	align-items: center;
}

.modal-content {
	display: flex;
	flex-direction: column;
	background-color: white;
	align-items: center;
	padding: 2% 2% 2%;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
	position: relative;
	width: 400px;
	height: 300px;
	border: 2px solid rgba(0, 0, 0, 0.3);
	background-color: rgba(100, 135, 73, 0.6);
	font-family: 'Manrope', sans-serif;
	font-size: 25px;
	font-weight: 300;
	text-align: center;
	line-height: normal;
	&__button {
		font-size: 25px;
		width: 70%;
		padding: 4%;
		font-family: 'Manrope', sans-serif;
		color: white;
		font-weight: 300;
		border-radius: 25px;
		background: rgba(28, 71, 27, 0.65);
		box-shadow: 7px 7px 13.9px 0px rgba(0, 0, 0, 0.40);
		border: none;
		cursor: pointer;
	}
}
.modal-content-review {
	display: flex;
	flex-direction: column;
	background-color: white;
	align-items: center;
	padding: 2% 2% 2%;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
	position: relative;
	width: 45%;
	height: 80%;
	border: 2px solid rgba(0, 0, 0, 0.3);
	background-color: rgba(100, 135, 73, 0.6);
	font-family: 'Manrope', sans-serif;
	font-size: 25px;
	font-weight: 300;
	text-align: center;
	line-height: normal;
	&__button {
		font-size: 25px;
		width: 70%;
		padding: 4%;
		font-family: 'Manrope', sans-serif;
		color: white;
		font-weight: 300;
		border-radius: 25px;
		background: rgba(28, 71, 27, 0.65);
		box-shadow: 7px 7px 13.9px 0px rgba(0, 0, 0, 0.40);
		border: none;
		cursor: pointer;
	}
}
.modal-content-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2% 2% 2%;
	position: relative;
}
.body-no-scroll {
	overflow: hidden;
}
@media (max-width: 768px) {
	.modal-content {
		display: flex;
		flex-direction: column;
		background-color: white;
		align-items: center;
		width: 100%;
		max-width: 100%;
		padding: 1%;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
		position: relative;
		height: 300px;
		border: 2px solid rgba(0, 0, 0, 0.3);
		background-color: rgba(100, 135, 73, 0.6);
		font-family: 'Manrope', sans-serif;
		font-size: 22px;
		font-weight: 300;
		text-align: center;
		line-height: normal;
		margin: 2%;
		justify-content: center;
		&__button {
			font-size: 25px;
			width: 50%;
			padding: 2%;
			font-family: 'Manrope', sans-serif;
			color: white;
			font-weight: 300;
			border-radius: 25px;
			background: rgba(28, 71, 27, 0.65);
			box-shadow: 7px 7px 13.9px 0px rgba(0, 0, 0, 0.40);
			border: none;
			cursor: pointer;
		}
	}
	.modal {
		padding: 1%;
		margin: 0;
		justify-content: center;
		width: 100%;
		align-items: center;
	}
	.modal-content-form {
		padding: 1%;
		margin: 0;
		width: 100%;
		align-items: center;
		justify-content: center;
	}
	.modal-content-review {
		width: 100%;
		&__button {
			font-size: 20px;
		}
	}
	.body-no-scroll {
		overflow: hidden;
	}
}
@media (min-width: 768px) and (max-width: 1024px) {

	.modal {
		padding: 0;
		margin: 0;
		justify-content: center;
		width: 100%;
		align-items: center;

	}
	.modal-content {
		display: flex;
		flex-direction: column;
		background-color: white;
		align-items: center;
		width: 98%;
		max-width: 100%;
		padding: 1%;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
		position: relative;
		height: 300px;
		border: 2px solid rgba(0, 0, 0, 0.3);
		background-color: rgba(100, 135, 73, 0.6);
		font-family: 'Manrope', sans-serif;
		font-size: 22px;
		font-weight: 300;
		text-align: center;
		line-height: normal;
		margin: 2%;
		justify-content: center;
		&__button {
			font-size: 25px;
			width: 50%;
			padding: 2%;
			font-family: 'Manrope', sans-serif;
			color: white;
			font-weight: 300;
			border-radius: 25px;
			background: rgba(28, 71, 27, 0.65);
			box-shadow: 7px 7px 13.9px 0px rgba(0, 0, 0, 0.40);
			border: none;
			cursor: pointer;
		}
	}
	.modal-content-form {
		padding: 1%;
		margin: 0;
		width: 100%;
		align-items: center;
		justify-content: center;
	}
	.modal-content-review {
		width: 100%;
		padding: 2%;
		margin: auto;
		height: 60%;
		&__button {
			font-size: 20px;
		}
	}
	.body-no-scroll {
		overflow: hidden;
	}
}
</style>
