<script setup>

</script>

<template>
<nav class="navigator">
	<ul>
		<li><a href="#home">Главная</a></li>
		<li><a href="#services">Услуги и цены</a></li>
		<li><a href="#about">О нас</a></li>
		<li><a href="#contact">Контакты</a></li>
		<li><a class="phone" href="tel:+79053329070">8 (905) 332-90-70</a></li>
	</ul>
</nav>
</template>

<style scoped lang="scss">
.navigator ul {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: space-around;
	a {
		color: white;
		text-decoration: none;
		text-align: center;
		font-family: Marmelad, serif;
		font-size: 25px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	a:hover {
		text-decoration: underline;
	}

}
.phone {
	color: white;
}
.logo {
	color: white;
}


@media (min-width: 768px) and (max-width: 1024px) {
	.header-nav ul {
		flex-direction: column; /* Элементы списка в столбик */
		gap: 0;
		text-align: center;
		font-size: 17px;
	;
	}

	li {

		padding: 1%;
		margin: 0.4%;
		border-bottom: 1px  #311D0A;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
	}

}
@media (max-width: 1025px) {
	.navigator li {
		border-bottom: 1px solid  rgba(0, 0, 0, 0.4);
		padding: 0;
		margin: 0;
	}
	.navigator ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		gap: 1.3rem;
		font-size: 17px;
		width: 100%;

		a {
			color: #2A543A;
			padding: 3%;
			margin: 1%;

			width: 100%;

		}

		a:hover {
			text-decoration: underline;
		}

	}
	.phone {
		display: none;
	}
	.logo {
		display: none;
	}
}


</style>