<script setup>
import { ref, onMounted } from 'vue';
import { fetchData } from '/src/services/apiService';
import {truncateString} from "@/utils/truncateStrung";
import UISeparator from '@/components/UI/UISeparator.vue';
import UIFormTicket from "@/components/UI/UIFormTicket.vue";

const categories = ref([]);

async function loadServices() {
	const data = await fetchData('/api/category/');
	categories.value = data;
}

onMounted(() => {
	loadServices();
});

function showMore(category) {
	category.isExpanded = !category.isExpanded;
	category.servicesLimit = category.isExpanded ? category.services.length : 4;
}

function shownServices(category) {
	return category.isExpanded ? category.services : category.services.slice(0, 4);
}
function formatPrice(price) {
	let priceStr = price.toString();
	priceStr = priceStr.replace(/\.0+$/, '');
	const parts = priceStr.split(/(?=(?:\d{3})+$)/);
	return parts.join('.');
}
</script>

<template>
<div class="service" id="services">
	<h1>Услуги</h1>
	<div class="service__subtitle">
		Быстрое и качественное решение Ваших проблем по разумным ценам!
	</div>
	<div class="documents__square-box">
		<div class="service__square-box__left-top"></div>
		<div class="service__square-box__center-top"></div>
		<div class="service__square-box__right-top"></div>
	</div>
	<div v-for="category in categories" :key="category.id">
		<h2>{{ category.name }}</h2>
		<div class="service__list">
			<div v-for="(service, index) in shownServices(category)" :key="index" class="service__list__items">
				<div class="service__list__items__top">
					<img :src='service.icon' alt="">
					<p :title="service.name">{{ truncateString(service.name, 75) }}</p>
				</div>
				<div class="service__list__items__bottom">
					<p>
						<text style="font-size: 23px">от</text>
						{{ formatPrice(service.price) }}р
					</p>
				</div>
			</div>
		</div>
		<div class="service__list__other" @click="showMore(category)">
			<UISeparator :width="'100%'"/>
			<p>{{ category.isExpanded ? 'Свернуть' : 'Все услуги' }}</p>
			<div class="service__list__other__svg">
				<svg viewBox="0 0 18 18" :class="{'rotate': category.isExpanded}" class="arrow-icon">
					<path d="M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6-6-6z"/>
				</svg>
			</div>
		</div>
	</div>


	<div class="service__square-box">
		<div class="service__square-box__left-bottom"></div>
		<div class="service__square-box____center-bottom">
			<UIFormTicket />
		</div>
		<div class="service__square-box__right-bottom"></div>
	</div>
</div>
</template>

<style lang="scss" scoped>
.service {
	background: #EAF8EF;
	width: 100%;
	background-size: cover;
	color: #3B220A;
	padding-bottom: 15%;

	h1 {
		color: #244709;
		text-align: center;
		font-family: Marmelad, serif;
		font-size: 60px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding: 1% 0 0;
		margin: auto;
	}
	h2 {
		font-family: Marmelad, serif;
		font-size: 30px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding: 1% 0 0 7%;
		margin: auto;
	}
	&__subtitle {
		color: #000;
		text-align: center;
		font-family: Manrope, serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		width: 25%;
		margin: auto;
		padding-bottom: 3%;
	}

	&__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		padding:  2% 10% 0 11%;
		&__items {
			position: relative;
			width: 20%;
			margin: 5% 2%;
			box-shadow: 15px 0px 15px -5px rgba(0, 0, 0, 0.40);
			transition: transform 0.3s ease, box-shadow 0.3s ease;

			&__top {
				background-color: #C9E3D2;
				height: 15rem;
				position: relative;
				margin: 0;
				padding: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					position: absolute;
					top:-20%;
					left: 50%;
					transform: translateX(-50%);
					width: 110px;
				}
				p {
					font-family: 'GOST2304 TypeB', serif;
					font-size: 25px;
					font-style: italic;
					text-align: center;
					position: absolute;

				}
			}
			&__bottom {
				background-color: #FFFFF1;
				height: 4rem;
				margin: 0;
				padding: 0;

				p {
					font-family: 'GOST2304 TypeA', serif;
					font-style: italic;
					text-align: right;
					color: #244709;
					font-size: 30px;
					bottom: 5%;
					right: 10%;
					padding: 0;
					margin: 0;
					position:absolute;
				}
			}

		}
		&__items:hover {
			transform: scale(1.05);
			box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
		}
		&__other {
			display: flex;
			align-items: center;
			justify-content: right;
			padding: 0 8% 0;
			color: #A19C8B;

			p {
				font-family: 'Manrope', serif;
				text-align: center;
				flex-basis: 10%;
				flex-shrink: 0;
				padding: 0;
				font-size: 20px;
				cursor: pointer;
			}
			&__svg {
				cursor: pointer;
				padding: 0;
			}
		}

	}


	&__square-box {
		background-color: transparent;
		padding: 0;
		&__right-top{
			border-top: 3px solid #A19C8B;
			border-right: 3px solid #A19C8B;
			height: 200px;
			width: 15%;
			right: 1%;
			position: absolute;
			margin-right: 5%;
		}
		&__right-bottom {
			border-bottom: 3px solid #A19C8B;
			border-right: 3px solid #A19C8B;
			height: 200px;
			width: 15%;
			right: 1%;
			position: absolute;
			margin-right: 5%;
		}
		&__center-top{
			border-top: 3px solid #A19C8B;
			width: 30%;
			height: 200px;
			margin: auto;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}
		&____center-bottom {
			position: absolute;
			display: flex;
			align-items: center;
			width: 100%;
		}
		&__left-top{
			border-top: 3px solid #A19C8B;
			border-left: 3px solid #A19C8B;
			width: 15%;
			height: 200px;
			left: 1px;
			position: absolute;
			margin-left: 5%;

		}
		&__left-bottom {
			border-bottom: 3px solid #A19C8B;
			border-left: 3px solid #A19C8B;
			width: 15%;
			height: 200px;
			left: 1px;
			position: absolute;
			margin-left: 5%;
		}
	}
}
.arrow-icon {
	transition: transform 0.3s ease;
	fill: currentColor;
	width: 24px;
	height: 24px;
	vertical-align: middle;
}

.rotate {
	transform: rotate(180deg);
}
.button {
	position: absolute;
	transform: translateX(-50%);
	left: 50%;
	bottom: 8%;
}
@media (max-width: 767px) {
	.service {
		padding-bottom: 15%;

		h1 {
			font-size: 32px;
			padding: 5% 0 2%;
		}
		h2 {
			font-size: 20px;
			padding: 2% 0 5% 7%;
		}
		&__subtitle {
			font-size: 20px;
			width: 80%;

		}

		&__list {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			padding:  2% 10% 0 11%;
			&__items {
				width: 46%;
				margin: 7% 2%;

				&__top {
					background-color: #C9E3D2;
					height: 10rem;
					position: relative;
					margin: 0;
					padding: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					img {
						transform: translateX(-50%);
						width: 70px;
					}
					p {
						font-size: 16px;
						padding: 0 3%;
					}
				}
				&__bottom {
					p {
						font-size: 20px;
					}
				}

			}
			&__other {
				display: flex;
				align-items: center;
				justify-content: right;
				padding: 0 8% 0;
				color: #A19C8B;


				p {
					font-family: 'Manrope', serif;
					text-align: center;
					flex-basis: 10%;
					flex-shrink: 0;
					padding: 0;
					font-size: 20px;
					cursor: pointer;
				}
				&__svg {
					cursor: pointer;
					padding: 0;
				}
			}

		}

		&__square-box {
			background-color: transparent;
			padding-bottom: 25%;
			&__right-top{
				display: none;
			}
			&__right-bottom {
				border-bottom: 3px solid #A19C8B;
				border-right: 3px solid #A19C8B;
				height: 120px;
				width: 15%;
				right: 1%;
				position: absolute;
				margin-right: 5%;
			}
			&__center-top{
				display: none;
			}
			&____center-bottom {
				position: absolute;
				display: flex;
				align-items: center;
				width: 100%;
			}
			&__left-top{
				display: none;

			}
			&__left-bottom {
				border-bottom: 3px solid #A19C8B;
				border-left: 3px solid #A19C8B;
				width: 15%;
				height: 120px;
				left: 1px;
				position: absolute;
				margin-left: 5%;
			}
		}
	}
	.arrow-icon {
		transition: transform 0.3s ease;
		fill: currentColor;
		width: 24px;
		height: 24px;
		vertical-align: middle;
	}

	.rotate {
		transform: rotate(180deg);
	}
	.button {
		position: absolute;
		transform: translateX(-50%);
		left: 50%;
		bottom: 8%;
	}
}
@media (min-width: 768px) and (max-width: 1024px) {
	.service {
		padding-bottom: 18%;

		h2 {
			padding: 2% 0 5% 7%;
		}

		&__subtitle {
			font-size: 20px;
			width: 80%;

		}

		&__list {
			padding:0;
			margin-left: 3%;
			align-items: center;

			&__items {

				&__top {

					img {
						transform: translateY(10%) translateX(-50%);
						width: 80px;
					}
					p {
						font-size: 16px;
						padding: 0 3%;
					}
				}
			}
		}

		&__square-box {
			background-color: transparent;
			padding-bottom: 0;

			&__right-top {
				display: none;
			}

			&__right-bottom {
				border-bottom: 3px solid #A19C8B;
				border-right: 3px solid #A19C8B;
				height: 120px;
				width: 15%;
				right: 1%;
				position: absolute;
				margin-right: 5%;
			}

			&__center-top {
				display: none;
			}

			&____center-bottom {
				position: absolute;
				display: flex;
				align-items: center;
				width: 100%;
			}

			&__left-top {
				display: none;

			}

			&__left-bottom {
				border-bottom: 3px solid #A19C8B;
				border-left: 3px solid #A19C8B;
				width: 15%;
				height: 120px;
				left: 1px;
				position: absolute;
				margin-left: 5%;
			}
		}

	}
}

</style>