<script setup>
import { ref, onMounted } from 'vue';
import { fetchData } from '/src/services/apiService';
import UISeparator from '@/components/UI/UISeparator.vue';
import UIFormTicket from "@/components/UI/UIFormTicket.vue";
const categories = ref([]);

async function loadDocuments() {
	const data = await fetchData('/api/document-category/');
	categories.value = data;
}


onMounted(() => {
	loadDocuments();
});

function showMore(category) {
	category.isExpanded = !category.isExpanded;
}

function showndocuments(category) {
	return category.isExpanded ? category.documents : category.documents.slice(0, 4);
}

</script>

<template>
<div class="documents">
	<h1>Документы</h1>
	<div class="documents__subtitle">
		В этом разделе вы можете найти все формы и заявления, которые мы предоставляем клиентам при любых сделках
	</div>
	<div class="documents__square-box">
		<div class="documents__square-box__left-top"></div>
		<div class="documents__square-box__center-top"></div>
		<div class="documents__square-box__right-top"></div>
	</div>
	<div v-for="category in categories" :key="category.id">
		<h2>{{ category.name }}</h2>
		<div class="documents__list">
			<div v-for="(documents, index) in showndocuments(category)" :key="index" class="documents__list__items">
				<div class="documents__list__items__top" :style="'border-color: ' + category.color">
				</div>
				<div class="documents__list__items__bottom">
				<a :href=documents.document target="_blank">{{documents.title}}</a>
				</div>
			</div>
		</div>
		<div class="documents__list__other" @click="showMore(category)">
			<UISeparator :width="'100%'"/>
			<p>{{ category.isExpanded ? 'Свернуть' : 'Смотреть все' }}</p>
			<div class="documents__list__other__svg">
				<svg viewBox="0 0 18 18" :class="{'rotate': category.isExpanded}" class="arrow-icon">
					<path d="M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6-6-6z"/>
				</svg>
			</div>
		</div>
	</div>


	<div class="documents__square-box">
		<div class="documents__square-box__left-bottom"></div>
		<div class="documents__square-box____center-bottom">
			<UIFormTicket />
		</div>
		<div class="documents__square-box__right-bottom"></div>
	</div>
</div>
</template>

<style lang="scss" scoped>
.documents {
	background: #EAF8EF;
	width: 100%;
	background-size: cover;
	color: #3B220A;
	padding-bottom: 15%;

	h1 {
		color: #244709;
		text-align: center;
		font-family: Marmelad, serif;
		font-size: 60px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding: 1% 0 0;
		margin: auto;
	}
	h2 {
		font-family: Marmelad, serif;
		font-size: 30px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding: 1% 0 0 7%;
		margin: auto;
	}
	&__subtitle {
		color: #000;
		text-align: center;
		font-family: Manrope, serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		width: 25%;
		margin: auto;
		padding-bottom: 3%;
	}

	&__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		padding:  0 10% 0 11%;
		&__items {
			position: relative;
			width: 20%;
			margin: 3% 2% 8%;
			transition: transform 0.3s ease, box-shadow 0.3s ease;

			&__top {
				background: url("../../../public/img/DocumentsSection/icon-document.png");
				background-size: 100% 100%;
				z-index: 2;
				height: 15rem;
				position: relative;
				margin: 0;
				padding: 0;
				border-right: 3px solid;
				border-left: 3px solid;
				border-top: 3px solid;
				p {
					font-family: 'GOST2304 TypeB', serif;
					font-size: 25px;
					font-style: italic;
					text-align: center;
					position: absolute;
					top: 4rem;
				}
			}
			&__bottom {
				z-index: 2;
				background-color: #C9E3D2;
				height: 7rem;
				width: 100%;
				border-top-left-radius: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: 13rem;


				a {
					color: #3B220A;
					text-align: center;
					font-family: 'GOST2304 TypeB', serif;
					text-decoration: none;
					font-size: 20px;
					font-style: italic;
					font-weight: 400;
					line-height: normal;

				}
			}

		}
		&__items:hover {
			transform: scale(1.05);
			box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
		}
		&__other {
			display: flex;
			align-items: center;
			justify-content: right;
			padding: 0 8% 0;
			color: #A19C8B;


			p {
				font-family: 'Manrope', serif;
				text-align: center;
				flex-basis: 10%;
				flex-shrink: 0;
				padding: 0;
				font-size: 20px;
				cursor: pointer;

			}
			&__svg {
				cursor: pointer;
				padding: 0;
			}
		}

	}


	&__square-box {
		background-color: transparent;
		padding: 0;
		&__right-top{
			border-top: 3px solid #A19C8B;
			border-right: 3px solid #A19C8B;
			height: 200px;
			width: 15%;
			right: 1%;
			position: absolute;
			margin-right: 5%;
		}
		&__right-bottom {
			border-bottom: 3px solid #A19C8B;
			border-right: 3px solid #A19C8B;
			height: 200px;
			width: 15%;
			right: 1%;
			position: absolute;
			margin-right: 5%;
		}
		&__center-top{
			border-top: 3px solid #A19C8B;
			width: 30%;
			height: 200px;
			margin: auto;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}
		&____center-bottom {
			position: absolute;
			display: flex;
			align-items: center;
			width: 100%;

		}
		&__left-top{
			border-top: 3px solid #A19C8B;
			border-left: 3px solid #A19C8B;
			width: 15%;
			height: 200px;
			left: 1px;
			position: absolute;
			margin-left: 5%;

		}
		&__left-bottom {
			border-bottom: 3px solid #A19C8B;
			border-left: 3px solid #A19C8B;
			width: 15%;
			height: 200px;
			left: 1px;
			position: absolute;
			margin-left: 5%;
		}
	}
}
.arrow-icon {
	transition: transform 0.3s ease;
	fill: currentColor;
	width: 24px;
	height: 24px;
	vertical-align: middle;
}

.rotate {
	transform: rotate(180deg);
}
.button {
	position: absolute;
	transform: translateX(-50%);
	left: 50%;
	bottom: 8%;
}
@media (max-width: 767px) {
	.documents {
		padding-bottom: 0;

		h1 {
			font-size: 30px;
		}
		h2 {
			font-size: 20px;
			padding: 3% 5%;
		}
		&__subtitle {
			font-size: 20px;
			width: 65%;
		}

		&__list {
			padding:  0 5% 0 5%;
			&__items {
				width: 45%;
				margin: 3% 2% 25%;
				&__top {
					height: 10rem;
					p {
						font-size: 20px;
						top: 4rem;
					}
				}
				&__bottom {
					height: 8rem;
					top: 7rem;
					margin: 0 0.3% 0 0.1%;
					a {
						color: #3B220A;
						text-align: center;
						font-family: 'GOST2304 TypeB', serif;
						text-decoration: none;
						font-size: 16px;
						font-style: italic;
						font-weight: 400;
						line-height: normal;

					}
				}

			}

			&__other {
				padding: 0 8% 0;
				p {
					font-size: 15px;

				}
				&__svg {
					cursor: pointer;
					padding: 0;
				}
			}

		}

		&__square-box {
			&__right-top{

				margin-right: 2%;
			}
			&__right-bottom {

				margin-right: 2%;
				height: 120px;
			}

			&__left-top{

				margin-left: 2%;

			}
			&__left-bottom {
				margin-left: 2%;
				height: 120px;
			}
		}
	}
}
@media (min-width: 768px) and (max-width: 1024px) {

		.documents {
			padding-bottom: 18%;

			h2 {
				padding: 2% 0 2% 7%;
			}

			&__subtitle {
				padding: 2% 0;
				font-size: 20px;
				width: 80%;

			}

			&__list {
				padding:0;
				margin-left: 3%;
				align-items: center;

				&__items {

					&__top {
						height: 13rem;
						width: 97%;
						margin: 0 1%;
						border-right: 1px solid;
						border-left: 1px solid;
						border-top: 1px solid;

						p {
							font-size: 20px;
							top: 5rem;
						}
					}

					&__bottom {
						height: 8rem;
						width: 100%;
						padding: 0;
						margin: 0;
						top: 7rem;
						a {
							font-size: 16px;
						}

					}

					img {
						transform: translateY(10%) translateX(-50%);
						width: 80px;
					}

			}
		}

			&__square-box {
				background-color: transparent;
				padding-bottom: 0;

				&__right-top {
					display: none;
				}

				&__right-bottom {
					border-bottom: 3px solid #A19C8B;
					border-right: 3px solid #A19C8B;
					height: 120px;
					width: 15%;
					right: 1%;
					position: absolute;
					margin-right: 5%;
				}

				&__center-top {
					display: none;
				}

				&____center-bottom {
					position: absolute;
					display: flex;
					align-items: center;
					width: 100%;
				}

				&__left-top {
					display: none;

				}

				&__left-bottom {
					border-bottom: 3px solid #A19C8B;
					border-left: 3px solid #A19C8B;
					width: 15%;
					height: 120px;
					left: 1px;
					position: absolute;
					margin-left: 5%;
				}
			}


	}
}
</style>