<script setup>
import { fetchData } from '/src/services/apiService';
import {ref, onMounted, onUnmounted} from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import Modal from '/src/components/UI/UIModal.vue';
import UIFormReview from '/src/components/UI/UIFormReview.vue';
import 'vue3-carousel/dist/carousel.css';

const fetchedReviews = ref([]);
const modalVisible = ref(false);
const modalReview = ref([]);
const currentReviewIndex = ref(0);

const itemsToShow = ref(window.innerWidth <=  1025 ? 2 : 3.95);

async function loadReviews() {
	fetchedReviews.value = await fetchData('/api/review/');
	}



function stars(rating) {
	const roundedRating = Math.round(rating);
	let starArray = [];
	for (let i = 1; i <= roundedRating; i++) {
		starArray.push('★');
	}
	return starArray.join('');
}

function openModal(review) {
	const index = fetchedReviews.value.findIndex(r => r.id === review.id);
	currentReviewIndex.value = index;
	modalReview.value = review;
	modalVisible.value = true;
}
const updateItemsToShow = () => {

	itemsToShow.value = window.innerWidth <= 1025 ? 2 : 3.95;
};

onMounted(() => {
	loadReviews();
	window.addEventListener('resize', updateItemsToShow);
});

onUnmounted(() => {
	window.removeEventListener('resize', updateItemsToShow);
});

function nextReview() {
	if (currentReviewIndex.value < fetchedReviews.value.length - 1) {
		currentReviewIndex.value++;
		modalReview.value = fetchedReviews.value[currentReviewIndex.value];
	}
}

function previousReview() {
	if (currentReviewIndex.value > 0) {
		currentReviewIndex.value--;
		modalReview.value = fetchedReviews.value[currentReviewIndex.value];
	}
}


</script>

<template>
<div class="reviews">
	<h1>Отзывы</h1>

	<Carousel :snap-align="'center'" :itemsToShow="itemsToShow">
		<Slide v-for="review in fetchedReviews" :key="review.id">
			<div class="carousel__item" @click="openModal(review)">
				<div class="carousel__item__top" :style="{ backgroundImage: 'url(' + review.avatar + ')', borderRadius: '30px' }">
					<p>{{ stars(review.rating) }}</p>

				</div>
				<div class="carousel__item__middle">

				</div>
				<div class="carousel__item__bottom">
					<button @click="openModal(review)">Читать отзыв</button>
					<p>{{ review.text.length > 200 ? review.text.substring(0, 100) + '...' : review.text }}</p>
				</div>
			</div>

		</Slide>
		<template #addons>
			<navigation>
				<template #next>
						<svg width="34" height="58" viewBox="0 0 34 58" fill="#A19C8B" xmlns="http://www.w3.org/2000/svg">
							<path id="Vector" d="M32.1456 32.4783C34.6485 30.3051 34.6147 26.7282 32.0628 24.5889L3.29201 0.470508C2.467 -0.221035 1.21307 -0.140991 0.491151 0.649494C-0.230765 1.4398 -0.147121 2.64122 0.677886 3.33285L29.4485 27.4511C30.2144 28.093 30.2231 29.0146 29.4797 29.6601L0.652024 54.6893C-0.160236 55.3946 -0.222018 56.5972 0.514133 57.3753C1.25028 58.1535 2.50552 58.2127 3.31787 57.5074L32.1456 32.4783Z" fill="#A19C8B"/>
						</svg>
				</template>
				<template #prev>

						<svg width="43" height="74" viewBox="0 0 43 74" fill="#A19C8B" xmlns="http://www.w3.org/2000/svg">
							<path d="M3.20146 32.0938C0.174714 34.7764 0.165575 39.2467 3.19125 41.9541L37.3026 72.4761C38.2808 73.3513 39.7832 73.2679 40.6584 72.2896C41.5337 71.3116 41.4502 69.8091 40.472 68.9338L6.36078 38.412C5.45279 37.5996 5.45508 36.4478 6.35407 35.651L41.2147 4.75556C42.197 3.88499 42.2876 2.38299 41.4171 1.40074C40.5465 0.418496 39.0445 0.327863 38.0621 1.19843L3.20157 32.0937L3.20146 32.0938Z" fill="#A19C8B"/>
						</svg>
				</template>
			</navigation>
		</template>
	</Carousel>
	<Modal :isReview="modalVisible" @update:isReview="modalVisible = $event">
		<div class="reviews__modal">
			<div class="reviews__modal__block">
				<div class="reviews__modal__avatar">
					<img :src="modalReview.avatar" alt="Аватар">
				</div>
				<div class="reviews__modal__name">
					<div>
					<p>{{ modalReview.name }}</p>
					<p>{{ stars(modalReview.rating) }}</p>
					</div>
				</div>
			</div>
			<div class="reviews__modal__button__prev" @click="previousReview" :class="{ 'disabled': currentReviewIndex === 0 }">
				<svg width="800px" height="800px" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
					<path d="M16.1795 3.26875C15.7889 2.87823 15.1558 2.87823 14.7652 3.26875L8.12078 9.91322C6.94952 11.0845 6.94916 12.9833 8.11996 14.155L14.6903 20.7304C15.0808 21.121 15.714 21.121 16.1045 20.7304C16.495 20.3399 16.495 19.7067 16.1045 19.3162L9.53246 12.7442C9.14194 12.3536 9.14194 11.7205 9.53246 11.33L16.1795 4.68297C16.57 4.29244 16.57 3.65928 16.1795 3.26875Z" />
				</svg>
			</div>
			<div class="reviews__modal__button__next" @click="nextReview" :class="{ 'disabled': currentReviewIndex ===   fetchedReviews.length - 1}">
				<svg width="800px" height="800px" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
					<g class="layer">
						<title>Layer 1</title>
						<g id="SVGRepo_bgCarrier" stroke-width="0"/>
						<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
						<g id="SVGRepo_iconCarrier" transform="rotate(-179.843 11.8575 12)">
							<path d="m16.18,3.27c-0.39,-0.39 -1.02,-0.39 -1.41,0l-6.65,6.64c-1.17,1.17 -1.17,3.07 0,4.24l6.57,6.58c0.39,0.39 1.02,0.39 1.41,0c0.4,-0.39 0.4,-1.02 0,-1.41l-6.57,-6.58c-0.39,-0.39 -0.39,-1.02 0,-1.41l6.65,-6.65c0.39,-0.39 0.39,-1.02 0,-1.41z" id="svg_1"/>
						</g>
					</g>
				</svg>
			</div>
		</div>
		<div class="reviews__modal__text">
			{{ modalReview.text }}

		</div>
		<div class="reviews__modal__button">
			<button @click="modalVisible=!modalVisible" class="reviews__modal__button__close-button">Закрыть</button>
			<div class="reviews__modal__button__close-icon" @click="modalVisible=!modalVisible">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
					<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
				</svg>
			</div>
		</div>

	</Modal>
	<UIFormReview />
</div>
</template>

<style lang="scss">

.reviews {
	background: #EAF8EF;
	width: 100%;
	background-size: cover;
	color: #3B220A;
	padding-bottom: 5%;
	&__modal {
		width: 100%;
		p {
			font-size: 30px;
			color: #FBB400;
		}
		&__name {
			width: 80%;
			display: flex;
			flex-direction: column;
			color: #FFFFFF;
			align-items: center;
			p {
				text-align: center;
				font-family: Manrope, serif;
				font-size: 35px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				padding: 0;
				margin: 0;
			}
			p:last-child {
				color: #FBB400;

			}
		}
		&__block {
			display: flex;
			flex-direction: column;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			p {
				color: #FFFFFF;
			}
		}
		&__avatar {
			width: 15rem;
			height: 15rem;
			float: left;
			margin: 2%;
			border-radius: 4px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 15%;
			}
		}
		&__text {
			padding: 1% 2%;
			width: 60%;
			color: #FFF;
			font-family: Marmelad, serif;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
		&__button {
			width: 25%;
			height: 10%;
			margin: auto;
			padding: 0;
			button {
				margin-bottom: 7%;
				width: 50%;
				height: 60px;
				flex-shrink: 0;
				color: #FFF;
				font-family: Marmelad, serif;
				font-size: 25px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				background-color: #244709;
				cursor: pointer;

			}
			&__close-button {
				position: absolute;
				left: 25%;
				bottom: -5%;
			}
			&__close-icon {

				position: absolute;
				top: 5%;
				right: 5%;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				background-color: #244709;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				display: none;
			}
			&__close-icon svg {
				width: 20px;
				height: 20px;
				color: white;
			}
			&__next {

				svg {
					width: 15%;
					position: absolute;
					right: 1%;
					cursor: pointer;
					justify-content: center;
					transition: transform 0.3s ease, box-shadow 0.3s ease;
					display: flex;
					align-items: center;
					fill: #717664;
					transform: translateY(-50%);
				}
				svg:hover {
					transform: translateY(-50%) scale(1.1);

				}


			}
			&__prev {
				svg {
					position: absolute;
					cursor: pointer;
					left: 1%;
					display: flex;
					align-items: flex-start;
					width: 15%;
					fill: #717664;
					transform: translateY(-50%);
				}
				svg:hover {
					transform: translateY(-50%) scale(1.1);

				}

			}
		}

	}

}
h1 {
	color: #244709;
	text-align: center;
	font-family: Marmelad, serif;
	font-size: 60px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	padding: 1% 0 0;
	margin: auto;
}

.carousel {
	padding: 0 10% 0 5%;
	background-image: none;
	justify-content: space-between;
}
.carousel__item {
	height: 30rem;
	padding: 1% 6%;
	width: 100%;
	&__top {
		height: 55%;
		background-position: center;
		background-color: #737373;
		max-width: 100%;
		z-index:  2;
		position: relative;
		img {
			width: 100%;
			max-width: 100%;
			padding: 0;
			margin: auto;
		}
		p {
			text-align: left;
			padding: 5%;
			color: #FBB400;
			font-size: 23px;
		}

	}
	&__middle {
		background-color: rgba(58, 28, 0, 0.42);
		height: 60%;
		width: 88%;

		text-align: center;
		font-family: Marmelad serif;
		font-size: 16.9px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		z-index: 1;
		position: absolute;
		top: 13rem;
		margin: 5% 6% 10% 0;

	}
	&__bottom {
		height: 65%;
		width: 88%;
		background-color: rgba(161, 161, 161, 0.25);
		position: absolute;
		z-index: 4;
		bottom: -5%;
		p {
			padding: 25% 7% 7%;
			color: #FFF;
			text-align: center;
			font-family: Marmelad serif;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;

		}
		button {
			background: none;
			border: none;
			color: #FFF;
			font-family: Marmelad serif;
			font-size: 30px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			text-decoration: none;
			position: relative;
			top: 9%;
			cursor: pointer;
			z-index: 5;
		}
		button:hover {
			color: #8f8f8f;
		}
	}
}


.carousel__slide--sliding {
	transition: 0.5s;

}

.carousel__slide {
	opacity: 100%;
	transform: none;

}
.carousel__item {
	transition: transform 0.3s ease, box-shadow 0.3s ease;
	cursor: pointer;
}
.carousel__item:hover {
	transform: scale(1.05);
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
.carousel__slide--active ~ .carousel__slide ~ .carousel__slide{
	opacity: 100%;
	transform: none;
}

.carousel__slide--prev {
	opacity: 100%;
	transform: none;
}

.carousel__slide--next {
	opacity: 100%;
	transform: none;
}


.carousel__slide--active {
	opacity: 100%;
	transform: none;

}

.carousel__prev
{
	position: absolute;
	left: auto;
	right: 0;
	padding-right: 5%;
	top: 60%

}
.carousel__next {
	top: 45%;
	padding-right: 5%;
}
@media (max-width: 767px) {
	.reviews {
		width: 100%;
		padding-top: 45%;

		&__modal {
			width: 100%;

			p {
				font-size: 25px;
				color: #FBB400;
			}
			&__name {
				p {
					font-size: 20px;

				}
			}

			&__block {
				width: 100%;
			}



			&__text {
				padding: 1% 2%;
				width: 100%;
				font-size: 16px;
			}

			&__button {
				button {
					display: none;
				}
				&__close-icon {
					display: flex;

				}

				&__next {
					svg {
						transform: translateY(-55%);
					}
					svg:hover {
						transform: translateY(-55%) scale(1.1);
					}
				}

				&__prev {
					svg {
						transform: translateY(-55%);
					}
					svg:hover {
						transform: translateY(-55%) scale(1.1);
					}
				}

			}
			&__avatar {
				width: 8rem;
				height: 8rem;

			}
		}

	}
	h1 {
		font-size: 30px;
	}

	.carousel {
		padding: 0;
	}
	.carousel__item {
		height: 25rem;
		padding: 15% 6%;
		width: 100%;
		&__top {
			height: 45%;
			background-position: center;
			background-color: #737373;
			max-width: 100%;
			z-index:  2;
			position: relative;
			img {
				width: 100%;
				max-width: 100%;
				padding: 0;
				margin: auto;
			}
			p {
				text-align: left;
				padding: 5%;
				color: #FBB400;
				font-size: 20px;
			}

		}
		&__middle {
			background-color: rgba(58, 28, 0, 0.42);
			height: 80%;
			width: 88%;

			text-align: center;
			font-family: Marmelad serif;
			font-size: 16.9px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			z-index: 1;
			position: absolute;
			top: 6rem;
			margin: 5% 6% 10% 0;

		}
		&__bottom {
			height: 65%;
			width: 88%;
			background-color: rgba(161, 161, 161, 0.25);
			position: absolute;
			z-index: 4;
			bottom: -5%;
			p {
				padding: 5% 3% 3%;
				color: #FFF;
				text-align: center;;
				font-size: 18px;
			}

			button:hover {
				color: #8f8f8f;
			}
			button {
				font-size: 20px;
				top: 4%;
			}
			&__close-icon {
				display: flex;

			}
		}
	}


	.carousel__slide--sliding {
		transition: 0.5s;

	}

	.carousel__slide {
		opacity: 100%;
		transform: none;

	}


	.carousel__slide--active ~ .carousel__slide ~ .carousel__slide{
		opacity: 100%;
		transform: none;
	}

	.carousel__slide--prev {
		opacity: 100%;
		transform: none;
	}

	.carousel__slide--next {
		opacity: 100%;
		transform: none;
	}


	.carousel__slide--active {
		opacity: 100%;
		transform: none;

	}

	.carousel__prev
	{
		display: none;

	}
	.carousel__next {
		display: none;
	}
}
@media (min-width: 768px) and (max-width: 1025px) {
	.reviews {
		width: 100%;

		&__modal {
			width: 90%;
			padding: 0 1%;
			p {
				font-size: 25px;
				color: #FBB400;
			}
			&__name {
				p {
					font-size: 25px;

				}
			}

			&__block {
				width: 100%;
			}



			&__text {
				padding: 1% 2%;
				width: 100%;
				font-size: 18px;
			}

			&__button {
				button {
					display: none;
				}
				&__close-icon {
					display: flex;

				}
				&__next {
					width: 5%;
					svg {
						transform: translateY(-58%);
					}
					svg:hover {
						transform: translateY(-58%) scale(1.1);
					}
				}

				&__prev {
					svg {
						transform: translateY(-58%);
					}
					svg:hover {
						transform: translateY(-58%) scale(1.1);
					}
				}

			}

		}
		.carousel__prev
		{
			position: absolute;
			left: auto;
			right: 0;
			padding-right: 3%;
			top: 60%

		}
		.carousel__next {
			top: 45%;
			padding-right: 3%;
		}
}
}

.reviews__modal__button__next.disabled, .reviews__modal__button__prev.disabled {
	cursor: default;
	opacity: 0.3;
	pointer-events: none;
	svg {
		fill: #9d9d9d;
	}
}


</style>