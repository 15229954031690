<script setup>
import {ref} from "vue";
import UIFormTicket from "@/components/UI/UIFormTicket.vue";



const listItems = ref([
	{ title: 'Перезвоним Вам в течение 15 минут', icon: '/img/icons/list_1.png' },
	{ title: 'Обсудим интересующую задачу', icon: '/img/icons/list_1.png' },
	{ title: 'Предложим варианты решения ', icon: '/img/icons/list_2.png' },
	{ title: 'Рассчитаем стоимость услуг', icon: '/img/icons/list_2.png' },
	{ title: 'Обсудим нюансы', icon: '/img/icons/list_3.png' },
	{ title: 'Договоримся о встрече', icon: '/img/icons/list_3.png' }
]);

</script>

<template>
<div class="questions">
	<div class="questions__header">
		<h1>Остались вопросы?</h1>
		<img src="/img/StagesSection/separator-stages.svg" alt="">
		<h2>Отправьте заявку и узнайте ответ за 5 минут</h2>
	</div>
	<div class="questions__middle">
		<div class="questions__list">
			<ul>
				<li v-for="(item, index) in listItems" :key="index">
					<img :src="item.icon" alt="" class="icon"/>
					{{ item.title }}
				</li>
			</ul>
		</div>
		<div class="questions__help">
			<p>Любая Ваша проблема по плечу нашим специалистам,
				поэтому скорее обращайтесь к нам, мы с радостью
				примем Ваш звонок.</p>
		</div>
	</div>
	<div class="questions__button">
	<UIFormTicket />
	</div>
	<img src="/img/DocumentsSection/line.svg" alt="" class="line">
</div>


</template>

<style lang="scss" scoped>

.questions {
	width: 100%;
	background-size: cover;
	color: #3B220A;
	padding-bottom: 2%;
	background: #EAF8EF;
	h1 {
		color: #244709;
		text-align: center;
		font-family: Marmelad, serif;
		font-size: 60px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding: 1% 0 0;
		margin: auto;
	}
	h2 {
		color: #3B220A;
		font-family: Manrope, serif;
		font-size: 40px;
		font-style: normal;
		font-weight: 300;
		line-height: normal;
		padding-left: 5%;
	}
	&__header {
		img {
			width: 50%;
			padding-top: 1%;
			padding-left: 25%;
		}
	}
	&__middle {
		padding-bottom: 3%;
		display: flex;
		p {
			color: #3B220A;

			padding: 15% 5%;
			font-size: 35px;
			font-style: normal;
			font-weight: 300;
			line-height: normal;
		}
	}
	&__list {
		width: 55%;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-right: 3px solid #A19C8B;

		ul {
			width: 70%;
			padding: 5% 0;
			display: flex;
			align-items: center;
			flex-direction: row;
			flex-wrap: wrap;

		}
		li {
			list-style-type: none;
			width: 100%;
			font-size: 30px;
			white-space: nowrap;
			padding: 3% 0;
			display: flex;
			flex-direction: row;
			align-items: center;

		}
		img {
			width: 33px;
			height: 33px;
			margin-right: 10px;
			padding: 0;

		}
	}
	&__help {
		width: 35%;
	}
	&__bottom {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

}
.line {
	width: 90%;
}
button {
	box-shadow: 15px 15px 15px -5px rgba(44, 44, 44, 0.4);
	margin: 2%;
	width: 491px;
	height: 90px;
	flex-shrink: 0;
	color: #FFF;
	font-family: Marmelad, serif;
	font-size: 30px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	background-color: #244709;
	cursor: pointer;
}
.line {
	width: 90%;
	padding:  2% 5% 0;
}
@media (max-width: 767px) {
	.questions {
		h1 {

			font-size: 30px;
		}
		h2 {

			font-size: 25px;
		}
		&__middle {
			padding-bottom: 1%;
			display: flex;
			flex-direction: column;
			width: 100%;
			p {
				color: #3B220A;
				padding: 0;
				font-size: 20px;
				width: 100%;
			}
		}
		&__list {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			border-right: none;


			ul {
				width: 100%;
				padding: 0;
				display: flex;
				align-items: center;
				flex-direction: row;
				flex-wrap: wrap;

			}
			li {

				width: 100%;
				padding: 3% 0;
				font-size: 18px;


			}
			img {
				width: 33px;
				height: 33px;
				margin-right: 10px;
				padding: 0;

			}
		}
		&__help {
			width: 95%;
			padding: 0 2%;
			text-align: center;
		}
		&__bottom {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		&__button {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

}
@media (min-width: 768px) and (max-width: 1024px) {
	.questions {

		h2 {

			font-size: 30px;
		}
		&__middle {
			padding-bottom: 1%;
			display: flex;
			flex-direction: column;
			width: 100%;
			p {
				color: #3B220A;
				padding: 0;
				font-size: 25px;
				width: 100%;
			}
		}
		&__list {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			border-right: none;


			ul {
				width: 90%;
				padding: 0 5%;
				display: flex;
				align-items: center;
				flex-direction: row;
				flex-wrap: wrap;

			}
			li {

				width: 100%;
				padding: 3% 0;
				font-size: 30px;



			}
			img {
				width: 33px;
				height: 33px;
				margin-right: 20px;
				padding: 0;

			}
		}
		&__help {
			width: 95%;
			padding: 0 2%;
			text-align: center;

		}
		&__bottom {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		&__button {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

	}
}
</style>