<script setup>
import { ref, watch, onUnmounted, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import UINavigator from "@/components/UI/UINavigator.vue";

const isMenuOpen = ref(false);
const route = useRoute();
const lastScrollPosition = ref(0);
const isVisible = ref(true);

function toggleMenu() {
	isMenuOpen.value = !isMenuOpen.value;
}
watch(route, () => {

	isMenuOpen.value = false;
});
function handleScroll() {
	const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
	if (currentScrollPosition > lastScrollPosition.value && currentScrollPosition > 50) { // 50 - минимальное расстояние для реакции
		isVisible.value = false;
	} else {
		isVisible.value = true;
	}
	lastScrollPosition.value = currentScrollPosition;
}
onMounted(() => {
	window.addEventListener('scroll', handleScroll, { passive: true });
});

onUnmounted(() => {
	window.removeEventListener('scroll', handleScroll);
});

</script>

<template>
<header :class="{ 'is-hidden': !isVisible }">
	<div class="header">
		<img src="img/logo/logo.png" class="header__img"/>
		<a class="phone-header" href="tel:+79053329070">8 (905) 332-90-70</a>
		<img src="/svg/lines.svg" alt="" @click="toggleMenu" class="menu-button"/>
		<nav class="header-nav hidden-nav">
			<ul>
				<UINavigator/>
			</ul>
		</nav>
	</div>
	<div class="header-content" v-show="isMenuOpen">
		<UINavigator />
	</div>

</header>
</template>


<style lang="scss" scoped>
.header {
	max-width: 1440px;
	width: 100%;
	background-color: rgba(42, 84, 58, 0.7);
	color: white;
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	align-items: center;
	justify-content: space-around;
	font-size: 25px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	z-index: 4;
	margin: auto;
	transition: transform 0.3s ease-in-out;
	nav {
		width: 100%;
	}
	img {
		width: 32px;
		padding: 0 5%;
	}

}

.phone-header {
	display: none;
}
.header-nav ul {

	white-space: nowrap;
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	gap: 0.7rem;
	padding: 3px;
	justify-content: space-around;
}
.menu-button {
	display: none !important;
	cursor: pointer;
	width: 10%;
	padding: 5%;
	right: 5px;
}

.is-hidden {
	transform: translateY(-100%);
	transition: transform 0.3s ease-in-out;
}

@media (max-width: 1025px) {
	.header-content {
		justify-content: space-between;
		margin: 2%;
		padding: 0;
		width: 100%;

	}
	.hidden-nav {
		display: none;
	}
	.menu-button {
		display: block !important;
		padding-right: 5%;

	}
	.header {
		position: relative;
		display: flex;
		flex-direction: row;
		background-color: #2A543A;
		width: 100%;
		justify-content: space-around;
		img {
			width: 24px;
			padding: 3%;
		}

	}
	.phone-header {
		display: block;
		color: #FFFFFF;
		text-decoration: none;
		padding-right: 10%;
	}
}
@media (min-width: 768px) and (max-width: 1024px) {
	.header {
		position: relative;
		display: flex;
		flex-direction: row;
		background-color: #2A543A;
		width: 100%;
		justify-content: space-around;
		img {
			width: 48px;
			padding: 3% 5%;
		}
		nav {
			width: 100%;
		}

	}
}


</style>