<script setup>
import { computed } from 'vue';
import { icons } from '/src/utils/icons';

const props = defineProps({
	name: String
});

const iconSvg = computed(() => icons[props.name] || '');
</script>

<template>
<div class="icons" v-html="iconSvg"></div>
</template>

<style scoped lang="scss">
</style>