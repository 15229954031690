<script setup>

</script>

<template>
<div class="stages">
	<h1>Этапы работ</h1>
	<img src="/img/StagesSection/stages.svg" alt="">
	<div class="stages__points">
		<div>
			<h2 style="text-align: center">1.</h2>
			<h2>Проверка</h2>
			<p>участка на обременения по строительству</p>
		</div>
		<div>
			<h2 style="text-align: center">2.</h2>
			<h2>Межевание</h2>
			<p>границ земельного участка</p>
		</div>
		<div>
			<h2 style="text-align: center">3.</h2>
			<h2>Тех. план</h2>
			<p>и декларация, грамотное оформление</p>
		</div>
		<div>
			<h2 style="text-align: center">4.</h2>
			<h2>Регистрация</h2>
			<p>прав собственности</p>
		</div>
	</div>
	<div class="stages__center">
		<h1>Кто такие кадастровые инженеры?</h1>
		<img src="/img/StagesSection/separator-stages.svg" alt="">
		<p>&emsp;&emsp;Кадастровая работа без специалиста сопряжена с трудностями из-за сложного
			сочетания требуемых технических, юридических и географических знаний.
			Вот основные причины, по которым специалист незаменим:</p>
		<ul>
			<li>
				Техническая экспертиза: Кадастровая съемка предполагает точные измерения
				и применение передовых технологий, таких как GPS, ГИС (географические информационные
				системы) и дистанционное зондирование.
			</li>
			<li>
				Юридические знания: Кадастровая инженерия связана не только с географией;
				она тесно переплетена с законами о собственности и земельными нормативами.
			</li>
			<li>
				Комплексное решение проблем: Споры по поводу земельных границ распространены
				и могут быть очень сложными, часто требуя посредничества между конфликтующими сторонами.
			</li>
		</ul>
	</div>
	<div class="stages__square-box">
		<div class="stages__square-box__left-top"></div>
		<div class="stages__square-box__center-top"></div>
		<div class="stages__square-box__center-left"></div>
		<div class="stages__square-box__center-right"></div>
		<div class="stages__square-box__right-top"></div>
		<h1>Мы в цифрах</h1>
		<img src="/img/StagesSection/compass.png" alt=""/>
		<div class="stages__square-box__p">

				<p class="stages__square-box__p__left-top" >Мы тщательно подбираем специалистов, поэтому помимо высокой
					квалификации наши сотрудники имеют стаж работы от 15 лет </p>
				<p class="stages__square-box__p__right-top" >Самое большое число символизирует коэффициент успешных
					государственных контрактов, которые мы качественно
					и своевременно исполнили</p>
				<p class="stages__square-box__p__left-bottom">Ведем работы в каждом районе нашей области, так что можете
					смело обращаться, мы точно сможем помочь вам</p>
				<p class="stages__square-box__p__right-bottom">Круглое число - наша главная заслуга, поскольку именно
					такое количество лет мы на рынке труда работаем для вас</p>


		</div>
		<div class="stages__square-box__left-bottom"></div>
		<div class="stages__square-box____center-bottom"></div>
		<div class="stages__square-box__right-bottom"></div>

	</div>
</div>
</template>

<style lang="scss" scoped>

.stages {
	background-image: url("../../../public/img/StagesSection/background-stage.png");
	width: 100%;
	background-size: cover;
	color: #3B220A;
	padding-bottom: 15%;

	h1 {
		color: #244709;
		text-align: center;
		font-family: Marmelad, serif;
		font-size: 60px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding: 1% 0 0;
		margin: auto;
	}
	h2 {
		text-align: left;
		font-size: 40px;
		padding-left: 0;
	}
	&__points {
		display: flex;
		padding: 0 2% 5%;
		font-family: Marmelad, serif;
		p {
			font-family: Marmelad, serif;
			font-size: 30px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			padding-left: 0;
		}
		div {
			width: 20%;
			padding: 0 4%;
		}
	}
	img {
		width: 100%;
		max-width: 100%;
		padding-top: 7%;
	}
	&__center {
		background: #FFFFF1;
		padding: 2% 0;
		margin: 0 4%;
		h1 {
			font-size: 60px;
		}
		img {
			width: 50%;
			padding-top: 2%;
			padding-left: 25%;
		}
		p, {
			font-family: Marmelad, serif;
			color: #244709;
			font-size: 30px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			padding: 0 9%;
			text-align: justify;
		}
		ul {
			font-family: Marmelad, serif;
			color: #244709;
			font-size: 30px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			padding: 0 11%;
			list-style-type: decimal;
		}
	}
	&__square-box {
		background-color: transparent;
		padding: 1% 0;

		&__p {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			position: relative;
			padding-bottom: 45%;
			p {
				color: #000;
				text-align: center;
				font-family: 'GOST2304 TypeA', serif;
				font-size: 30px;
				font-style: italic;
				font-weight: 400;
				line-height: normal;
				width: 23%;
				position: absolute;

			}
			&__left-top {
				top: 0;
				left: 10%;
			}
			&__right-top {
				top: 0;
				right: 10%;
			}
			&__left-bottom {
				bottom: -20%;
				left: 10%;
			}
			&__right-bottom {
				bottom: -20%;
				right: 10%;
			}
		}

		&__right-top{
			border-top: 3px solid #3B220A;
			border-right: 3px solid #3B220A;
			height: 200px;
			width: 15%;
			right: 1%;
			position: absolute;
			margin: 0 3%;
		}
		&__right-bottom {
			border-bottom: 3px solid #3B220A;
			border-right: 3px solid #3B220A;
			height: 200px;
			width: 15%;
			right: 1%;
			position: absolute;
			margin-right: 3%;
		}
		&__center-top{
			border-top: 3px solid #3B220A;
			width: 30%;
			height: 200px;
			margin: auto;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}
		&____center-bottom {
			border-bottom: 3px solid #3B220A;
			width: 30%;
			height: 200px;
			margin: auto;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			align-items: center;

		}
		&__left-top{
			border-top: 3px solid #3B220A;
			border-left: 3px solid #3B220A;
			width: 15%;
			height: 200px;
			left: 1px;
			position: absolute;
			margin-left: 4%;

		}
		&__center-left{
			border-left: 3px solid #3B220A;
			width: 15%;
			height: 200px;
			left: 1px;
			position: absolute;
			margin-left: 4%;
			margin-top: 25%;
		}
		&__center-right{
			border-right: 3px solid #3B220A;
			width: 15%;
			height: 200px;
			right: 1px;
			position: absolute;
			margin-right: 4%;
			margin-top: 25%;
		}
		&__left-bottom {
			border-bottom: 3px solid #3B220A;
			border-left: 3px solid #3B220A;
			width: 15%;
			height: 200px;
			left: 1px;
			position: absolute;
			margin-left: 4%;
		}
		img {
			width: 40%;
			padding: 5% 30%;
			position: absolute;
		}
	}
}
@media (max-width: 767px) {
	.stages {
		padding-bottom: 15%;

		h1 {
			font-size: 30px;
			padding: 1% 0 0;
			margin: auto;
		}
		h2 {
			text-align: left;
			font-size: 12px;
		}
		&__points {
			padding: 0 2% 2%;
			p {
				font-size: 12px;
			}
			div {
				width: 22%;
				padding: 0 2%;
			}
		}
		img {
			width: 100%;
			max-width: 100%;
			padding-top: 7%;
		}
		&__center {
			background: #FFFFF1;
			padding: 1% 0;
			margin: 0 2%;
			h1 {
				font-size: 30px;
			}
			img {
				width: 50%;
				padding-top: 2%;
				padding-left: 25%;
			}
			p, {
				font-size: 20px;
				padding: 0 4%;
			}
			ul {
				font-size: 18px;
				padding: 0 9%;
			}
		}
		&__square-box {
			background-color: transparent;
			padding: 5% 0 15%;

			&__p {
				padding: 45%;
				p {
					font-size: 15px;

					width: 25%;
				}
			}

			&__right-top{
				height: 100px;
			}
			&__right-bottom {
				height: 100px;
			}
			&__center-top{
				height: 100px;

			}
			&____center-bottom {
				height: 100px;

			}
			&__left-top{
				height: 100px;
			}
			&__center-left{
				display: none;
			}
			&__center-right{
				display: none;
			}
			&__left-bottom {
				height: 100px;

			}
			img {
				width: 40%;
				padding: 30%;
				position: absolute;
			}
		}
	}
}
@media (min-width: 768px) and (max-width: 1024px) {
	.stages {
		padding-bottom: 18%;
		h2 {
			text-align: left;
			font-size: 25px;
		}
		&__points {
			padding: 0 2% 2%;
			p {
				font-size: 25px;
				width: 25%;
			}
			div {
				width: 21%;
				padding: 0 2%;
			}
		}
		img {
			width: 100%;
			max-width: 100%;
			padding-top: 7%;
		}
		&__center {
			background: #FFFFF1;
			padding: 1% 0;
			margin: 0 2%;
			h1 {
				font-size: 30px;
			}
			img {
				width: 50%;
				padding-top: 2%;
				padding-left: 25%;
			}
			p, {
				font-size: 20px;
				padding: 0 4%;
			}
			ul {
				font-size: 18px;
				padding: 0 9%;
			}
		}
		&__square-box {
			background-color: transparent;
			padding: 5% 0 0;

			&__p {
				padding: 25%;
				p {
					font-size: 25px;

					width: 25%;
				}
			}

			&__right-top{
				height: 100px;
			}
			&__right-bottom {
				height: 100px;
			}
			&__center-top{
				height: 100px;

			}
			&____center-bottom {
				height: 100px;

			}
			&__left-top{
				height: 100px;
			}
			&__center-left{
				display: none;
			}
			&__center-right{
				display: none;
			}
			&__left-bottom {
				height: 100px;

			}
			img {
				width: 40%;
				padding: 10% 30%;
				position: absolute;
			}
		}

	}
}
</style>