<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
	rating: {
		type: Number,
		default: 0
	}
});

const emit = defineEmits(['update:rating']);

const hoverRating = ref(props.rating);

function updateRating(newRating) {
	emit('update:rating', newRating);
}

function resetRating() {
	hoverRating.value = props.rating;
}

watch(() => props.rating, (newVal) => {
	hoverRating.value = newVal;
});
</script>

<template>
<div class="star-rating">
    <span v-for="star in 5" :key="star" class="star" :class="{ 'active': star <= hoverRating }" @click="updateRating(star)" @mouseover="hoverRating = star" @mouseleave="resetRating">
		★
    </span>
</div>
</template>

<style scoped lang="scss">
.star {
	cursor: pointer;
	font-size: 42px;
	color: grey;
}

.star.active {
	color: #FBB400;
}

</style>