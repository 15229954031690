import api from '/src/api/api';

export async function sendForm(apiEndpoint, formData) {
    try {

        const response = await api.post(apiEndpoint, formData);
        return response.data;
    } catch (error) {
        console.error('Ошибка в sendForm:', error);
        throw error;
    }
}

export async function fetchData(apiEndpoint) {
    try {
        const response = await api.get(apiEndpoint );
        return response.data.results;
    } catch (error) {
        console.error('Ошибка при получении категорий:', error);
        throw error;
    }
}
