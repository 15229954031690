<script setup>
import { ref, watch, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import UINavigator from "@/components/UI/UINavigator.vue";
import UIYandexMetrica from "@/components/UI/UIYandexMetrica.vue";

const isMenuOpenFooter = ref(false);
const route = useRoute();
function toggleMenuFooter() {
	isMenuOpenFooter.value = !isMenuOpenFooter.value;
	if (isMenuOpenFooter.value) {
		nextTick(() => {
			scrollToFooter();
		});
	}
}

watch(route, () => {

	isMenuOpenFooter.value = false;
});

function scrollToFooter() {
	const footerElement = document.querySelector('.footer');
	if (footerElement) {
		footerElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
	}
}
</script>

<template>
<footer>
	<div class="footer-content" v-show="isMenuOpenFooter">
		<UINavigator />
	</div>
	<div class="footer">
		<a class="phone-footer" href="tel:+79053329070">8 (905) 332-90-70</a>
		<img src="/svg/lines.svg" alt="" @click="toggleMenuFooter" class="menu-button"/>
		<nav class="footer-nav hidden-nav">
			<ul>
				<UINavigator/>
			</ul>
		</nav>

	</div>
	<div class="developed-by-container">
		<svg width="40" height="40" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_801_5)">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M48.9046 17.5557C68.5717 5.99902 78.4052 0.2207 89.1885 0.00800062C89.7296 -0.00266687 90.2703 -0.00266687 90.8113 0.00800062C101.595 0.2207 111.428 5.99902 131.095 17.5557C150.762 29.1124 160.596 34.8906 166.169 44.289C166.448 44.7603 166.719 45.2371 166.98 45.719C172.191 55.3301 172.191 66.8868 172.191 90C172.191 113.113 172.191 124.67 166.98 134.281C166.719 134.763 166.448 135.24 166.169 135.711C160.596 145.109 150.762 150.888 131.095 162.444C111.428 174.001 101.595 179.779 90.8113 179.992C90.2703 180.003 89.7296 180.003 89.1885 179.992C78.4052 179.779 68.5717 174.001 48.9046 162.444C29.2375 150.888 19.404 145.109 13.8313 135.711C13.5518 135.24 13.2814 134.763 13.02 134.281C7.80933 124.67 7.80933 113.113 7.80933 90C7.80933 66.8868 7.80933 55.3301 13.02 45.719C13.2814 45.2371 13.5518 44.7603 13.8313 44.289C19.404 34.8906 29.2375 29.1124 48.9046 17.5557ZM53.3137 97.0295L44.9398 92.1088C43.3449 91.1717 43.3449 88.8283 44.9398 87.8912L53.3137 82.9705C54.9089 82.0331 56.9027 83.2048 56.9027 85.0793V94.9207C56.9027 96.7952 54.9089 97.9668 53.3137 97.0295ZM135.06 92.1088L126.686 97.0295C125.091 97.9668 123.097 96.7952 123.097 94.9207V85.0793C123.097 83.2048 125.091 82.0331 126.686 82.9705L135.06 87.8912C136.655 88.8283 136.655 91.1717 135.06 92.1088ZM94.7173 139.514L118.028 98.4213C119.609 95.6345 118.573 92.3354 116.203 90.7858C115.833 90.5438 115.833 89.4561 116.203 89.2139C118.573 87.6646 119.609 84.3654 118.027 81.5786L117.343 80.3709L94.7173 40.4862C92.5683 36.6982 87.1965 36.6982 85.0478 40.4862L61.7374 81.5786C60.1566 84.3654 61.1925 87.6646 63.5617 89.2142C63.9322 89.4561 63.9322 90.5438 63.5617 90.7861C61.1925 92.3354 60.1566 95.6345 61.7374 98.4213L69.6131 112.305L85.0478 139.514C87.1965 143.302 92.5686 143.302 94.7173 139.514Z" fill="#4C73DC"/>
				<path d="M94.7173 139.514L118.028 98.4213C119.608 95.6345 118.573 92.3353 116.203 90.7858C115.833 90.5438 115.833 89.4561 116.203 89.2139C118.573 87.6646 119.608 84.3654 118.027 81.5786L117.342 80.3708C108.955 98.8435 90.7959 111.778 69.613 112.305L85.0477 139.514C87.1964 143.302 92.5685 143.302 94.7173 139.514Z" fill="url(#paint0_linear_801_5)"/>
			</g>
			<defs>
				<linearGradient id="paint0_linear_801_5" x1="89.9999" y1="64.4312" x2="89.9999" y2="151.689" gradientUnits="userSpaceOnUse">
					<stop stop-color="#4C73DC"/>
					<stop offset="1" stop-color="white"/>
				</linearGradient>
				<clipPath id="clip0_801_5">
					<rect width="164.381" height="180" fill="white" transform="translate(7.80933)"/>
				</clipPath>
			</defs>
		</svg>
		<a href="https://compass-pro.ru" target="_blank" rel="noopener noreferrer" class="developed-by">Разработано ООО "Компас ПРО"</a>
	</div>
	<UIYandexMetrica />

</footer>
</template>


<style lang="scss" scoped>
.footer {
	max-width: 1440px;
	width: 100%;
	background-color: #2A543A;
	color: white;
	padding: 1% 0;
	display: flex;
	align-items: center;
	justify-content: space-around;
	font-size: 25px;
	font-style: normal;
	font-family: Marmelad, serif;
	font-weight: 400;
	line-height: normal;
	z-index: 1;
	margin: auto;
	nav {
		width: 100%;
	}

}

.phone-footer {
	display: none;
}
.footer-nav ul {

	white-space: nowrap;
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	gap: 0.7rem;
	padding: 3px;
	justify-content: space-around;
}
.menu-button {
	display: none !important;
	cursor: pointer;
	width: 10%;
	padding: 5%;
	right: 5px;
}
.developed-by-container {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	color: #fff;
	padding: 20px 0;
	background-color: #2A543A;
	width: 100%;
}

.developed-by {
	color: #3975f8;
	font-size: 14px;
}
@media (max-width: 1025px) {
	.developed-by-container {
		padding: 0 0  1% 0 ;

	}
	.footer-content {
		justify-content: space-between;
		margin: 2%;
		padding: 0;
		/* .logo {
			color: red;
			padding: 8% 5%;
		} */
		width: 100%;
	}
	.hidden-nav {
		display: none;
	}
	.menu-button {
		display: block !important;
		padding-right: 5%;

	}
	.footer {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		background-color: #2A543A;
		width: 100%;
	}
	.phone-footer {
		display: block;
		color: #FFFFFF;
		text-decoration: none;
		padding-right: 10%;
	}
}
</style>