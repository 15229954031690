<script setup>
const props = defineProps({
		width: {
			type: String,
			default: '100%',
		},
		height: {
			type: String,
			default: '18px',
		},
		fillColor: {
			type: String,
			default: '#3A1C00',
		},
		fillOpacity: {
			type: Number,
			default: 0.42,
		}
});

</script>

<template>
<svg :width="props.width" :height="props.height" viewBox="0 0 1256 18" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M15 7.5L-7.91287e-07 0.339746L7.91287e-07 17.6603L15 10.5L15 7.5ZM1293 10.4999C1293.83 10.4999 1294.5 9.82831 1294.5 8.99988C1294.5 8.17145 1293.83 7.49988 1293 7.49988L1293 10.4999ZM13.5 10.5L1293 10.4999L1293 7.49988L13.5 7.5L13.5 10.5Z" :fill="props.fillColor" :fill-opacity="props.fillOpacity"/>
</svg>
</template>

<style scoped lang="scss">

</style>