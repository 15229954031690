<script>
export default {
	name: 'YandexMap',
	props: {
		mapId: {
			type: String,
			required: true,
		},
		width: {
			type: String,
			default: '100%',
		},
		height: {
			type: String,
			default: '350px',
		},
		lang: {
			type: String,
			default: 'ru_RU',
		},
		scroll: {
			type: Boolean,
			default: true,
		}
	},
	mounted() {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.charset = 'utf-8';
		script.async = true;
		const constructorKey = process.env.VUE_APP_YANDEX_MAPS_CONSTRUCTOR_KEY;
		script.src = `https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%${constructorKey}&amp;&width=${encodeURIComponent(this.width)}&height=${encodeURIComponent(this.height)}&lang=${this.lang}&scroll=${this.scroll}`;
		this.$el.appendChild(script);
	},
}
</script>

<template>
<div :id="mapId" :style="{ width: width, height: height }" class="yandex-map"></div>
</template>

<style scoped>

.yandex-map {
	padding-top: 2%;
}

</style>