<script setup>
import { onMounted } from 'vue';
const metrikaId = process.env.VUE_APP_YANDEX_METRICA_ID;

onMounted(() => {
	(function (m, e, t, r, i, k, a) {
		m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments); };
		m[i].l = 1 * new Date();
		k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a);
	})(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

	ym(metrikaId, 'init', {
		clickmap: true,
		trackLinks: true,
		accurateTrackBounce: true,
		webvisor: true
	});
});
</script>
<template>
<div style="display:none;">
	<!-- Изображение для noscript -->
	<noscript>
		<div>
			<img :src="'https://mc.yandex.ru/watch/' + metrikaId" style="position:absolute; left:-9999px;" alt="" />
		</div>
	</noscript>
</div>
</template>