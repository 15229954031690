export const icons = {
    address: '<img class="icon" src="/img/ContactIcons/address.svg" alt="dots icon">',
    phone: '<img class="icon" src="/img/ContactIcons/phone.svg" alt="dots icon">',
    email: '<img class="icon" src="/img/ContactIcons/email.svg" alt="dots icon">',
    vk: '<img class="icon" src="/img/ContactIcons/vk.svg" alt="dots icon">',
    telegram: '<img class="icon" src="/img/ContactIcons/telegram.svg" alt="dots icon">',
    ok: '<img class="icon" src="/img/ContactIcons/ok.svg" alt="dots icon">',
    WhatsApp: '<img class="icon" src="/img/ContactIcons/whatsapp.svg" alt="dots icon">',
};

