<script setup>
import Modal from '/src/components/UI/UIModal.vue';
import { sendForm } from '/src/services/apiService';
import { cleanPhoneNumber } from '/src/utils/cleaningPhone';
import {ref, watchEffect} from 'vue';

const modalVisible = ref(false);
const modalMessage = ref('');


import {vMaska} from "maska";
const props = defineProps({
	shortTicket: Boolean,
	apiEndpoint: String,
	formContext: String
});

const formData = ref({
	name: '',
	email: '',
	phone_number: '',
	amount: null,
	message: '',
	consent: false
});

const emit = defineEmits(['formSubmitted']);

const handleSubmit = async () => {
	try {
		const formDataCopy = { ...formData.value };
		formDataCopy.phone_number = formDataCopy.phone_number ? cleanPhoneNumber(formDataCopy.phone_number) : '';
		await sendForm(props.apiEndpoint, formDataCopy);
		modalMessage.value = 'Спасибо за обращение! \n' +
			'Ваше обращение успешно отправлено, наш специалист\n' +
			' с Вами свяжется\n' +
			' в ближайшее время!';
		formData.value = {
			name: '',
			email: '',
			phone_number: '',
			amount: null,
			message: '',
			consent: false
		}
		modalVisible.value = true;
		setTimeout(() => {
			modalVisible.value = false;
			emit('formSubmitted');
		}, 3000);


	} catch (error) {
		console.error('Ошибка при отправке формы:', error);
		modalMessage.value = 'Произошла ошибка при отправке формы.';
		modalVisible.value = true;
	}
};
watchEffect(() => {
	if (props.shortTicket) {
		document.body.classList.add('body-no-scroll');
	} else {
		document.body.classList.remove('body-no-scroll');
	}
});
</script>

<template>
<form class='main-form' @submit.prevent="handleSubmit">
	<h1>
		Получить консультацию
		бесплатно
	</h1>
	<div>
		<input
			id="fullName"
			v-model="formData.name"
			:placeholder="shortTicket ? 'Ваше имя' : 'Имя*'"
			type="text" required
		>
	</div>
	<div v-if="!shortTicket">
		<input
			id="email"
			v-model="formData.email"
			type="email"
			placeholder="Email*"
			required
		>
	</div>
	<div>
		<input
			v-maska
			data-maska="+7 (9##) ###-##-##"
			id="number"
			v-model="formData.phone_number"
			:placeholder="shortTicket ? 'Ваш телефон' : 'Телефон*'"
			type="tel"
			required
		>
	</div>
	<div v-if="!shortTicket">
		<textarea
			id="message"
			v-model="formData.message"
			placeholder="Какая услуга заинтересовала?">
		</textarea>
	</div>


	<div  class="main-form__personal-data">
		<input :id="`consent_support_${props.formContext}`" v-model="formData.consent" type="checkbox" required>
		<label :for="`consent_support_${props.formContext}`">Даю согласие на обработку персональных данных и я ознакомился с
			<a href="/docs/согласие_о_персональных_данных.docx" target="_blank">
				Политикой конфиденциальности
			</a>
		</label>
	</div>
	<button type="submit">Отправить заявку</button>
</form>
<Modal :isVisible="modalVisible" @update:isVisible="modalVisible = $event">
	<p>{{ modalMessage }}</p>
</Modal>
</template>

<style scoped lang="scss">
.main-form {
	width: 75%;
	padding: 1% 3% 6%;
	border: 2px solid rgba(0, 0, 0, 0.3);
	background-color: rgba(100, 135, 73, 0.6);
	font-family: 'Manrope', sans-serif;
	font-size: 25px;
	font-weight: 300;
	display: flex;
	flex-direction: column;
	align-items: center;

	h1 {
	padding: 4%;
	color: #FFFFFF;
	font-family: 'Manrope', sans-serif;
	text-align: center;
	font-size: 30px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	}

	input {
		width: 90%;

		font-family: 'Marmelad', sans-serif;
		padding: 5%;
		margin-bottom: 12px;
		background-color: rgba(100, 135, 73, 0.6);
		border-radius: 0;
		font-size: 25px;
		font-weight: 300;
		border: 2px solid rgba(255, 255, 255, 0.39);
	}

	textarea {
		width: 90%;
		color: #d02e2e;
		padding: 4px 0 7px 16px;
		margin-bottom: 12px;
		font-size: 25px;
		font-weight: 300;
		border: 2px solid rgba(255, 255, 255, 0.39);
	}

	input::placeholder, textarea::placeholder {
		color: rgba(255, 255, 255, 0.39);
		text-align: left;
		font-family: 'Marmelad', sans-serif;
		font-size: 27.6px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	button {

		font-size: 25px;
		width: 70%;
		padding: 2%;
		margin-top: 2%;
		font-family: 'Manrope', sans-serif;
		color: white;
		font-weight: 300;
		border-radius: 25px;
		background: rgba(28, 71, 27, 0.65);
		box-shadow: 7px 7px 13.9px 0px rgba(0, 0, 0, 0.40);
		border: none;
		cursor: pointer;

	}
	&__personal-data {
		display: flex;
		align-items: center;
		width: 85%;
		color: #FFF;
		font-family: 'Manrope', sans-serif;
		font-size: 13px;
		font-style: normal;
		font-weight: 300;
		line-height: normal;
		padding: 0;
		input {
			width: 10%;
			margin: 1%;
		}
		a {
			color: white;
		}
	}

}
.body-no-scroll {
	overflow: hidden;
}
@media (max-width: 767px) {
	.main-form {
		width: 90%;
		margin: 0 1% 0 1.5%;

		h1 {
			padding: 1%;
			font-size: 20px;
		}

		input {
			font-size: 20px;
		}

		textarea {
			font-size: 20px;
		}

		input::placeholder, textarea::placeholder {
			color: rgba(255, 255, 255, 0.39);
			text-align: left;
			font-family: 'Marmelad', sans-serif;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
		&__personal-data {
			font-size: 10px;
		}
		button {

			font-size: 20px;
			width: 60%;
			padding: 4%;
			margin-top: 4%;
		}
	}
}
@media (min-width: 768px) and (max-width: 1024px) {
	.main-form {
		width: 100%;
	}
}
</style>