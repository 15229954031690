<script setup>

</script>

<template>
<div class="about_us" id="about">
	<h1>О нас</h1>
	<p class="about_us__p-right">У нас при выборе скорости, качества и выгоды можно взять всё!</p>
	<img class="about_us__img-left" src="/img/AboutSection/quality-medal-svgrepo-com 1.png" alt="" height="214" width="214"/>
	<p class="about_us__p-left">Скидки для постоянных клиентов обсуждаются в личном порядке</p>
	<img class="about_us__img-right" src="/img/AboutSection/quality-medal-svgrepo-com 2.png" height="218" width="206"/>
	<img class="about_us__img-center" src="/img/AboutSection/line-up.svg"/>
	<p class="about_us__p-bottom">Каждая задача индивидуальна, поэтому мы со всей заботой и ответственностью подходим к её решению</p>
</div>
</template>

<style lang="scss" scoped>

.about_us {
	background: #EAF8EF;
	width: 100%;
	background-size: cover;
	color: #3B220A;
	padding-bottom: 70%;

	h1 {
		color: #244709;
		text-align: center;
		font-family: Marmelad, serif;
		font-size: 60px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding: 1% 0 0;
		margin: auto;
	}

	h2 {
		font-family: Marmelad, serif;
		font-size: 30px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding: 1% 0 0 7%;
		margin: auto;
	}

	&__subtitle {
		color: #000;
		text-align: center;
		font-family: Manrope, serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		width: 25%;
		margin: auto;
		padding-bottom: 3%;
	}
	p {
		width: 45%;
		position: absolute;
		text-align: center;
		font-family: Marmelad, serif;
		font-size: 30px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	img {
		position: absolute;
	}
	&__p-right {
		right: 12px;
		padding-top: 5%;
	}
	&__p-left {
		left: 12px;
		padding-top: 25%;
	}
	&__p-bottom {
		left: 50%;
		transform: translateX(-50%);
		padding-top: 55%;
	}
	&__img-right {
		right: 5%;
		padding-top: 27%;
	}
	&__img-left {
		left: 10%;
	}
	&__img-center {
		width: 100%;
		padding-top: 7%;
	}
}
@media (max-width: 767px) {
	.about_us {
		padding-bottom: 70%;

		h1 {
			font-size: 30px;

		}

		h2 {
			font-size: 20px;
		}

		&__subtitle {
			font-size: 20px;
		}
		p {
			width: 50%;
			font-size: 15px;
		}
		&__p-right {
			right: 10%;
			transform: translateY(-50%);
		}
		&__p-left {
			left: 1%;
			padding-top: 13%;

		}
		&__p-bottom {
			right: 1%;
			transform: none;
			padding-top: 30%;
		}
		&__img-right {
			right: 5%;
			padding-top: 27%;
		}
		&__img-left {
			left: 10%;
		}
		&__img-center {
			width: 100%;
			padding-top: 10%;
		}
	}
}
@media (min-width: 768px) and (max-width: 1024px) {
	.about_us {
		padding-bottom: 90%;
		&__subtitle {
			font-size: 20px;
		}
		p {
			width: 50%;
			font-size: 25px;
		}
		&__img-right {
			right: 5%;
			padding-top: 47%;
		}
		&__img-left {
			left: 10%;
			padding-top: 1%;
		}
		&__img-center {
			width: 100%;
			padding-top: 12%;
		}
		&__p-left {
			left: 12px;
			padding-top: 20%;
		}
	}

}
</style>