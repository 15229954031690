<script setup>
import {ref} from 'vue';
import Modal from '/src/components/UI/UIModal.vue';
import UIStarRating from '/src/components/UI/UIStarRating.vue';
import { sendForm } from '/src/services/apiService';

const isFormVisible = ref(false);
const modalVisible = ref(false);
const modalMessage = ref('');
const form = ref({
	name: '',
	rating: 0,
	text: '',
	avatar: null
});

function handleFileUpload(event) {
	const file = event.target.files[0];
	if (!file) return;
	if (file.size > 2097152) { // 2MB
		alert('Файл слишком большой, выберите файл размером до 2МБ.');
		return;
	}
	if (!['image/jpeg', 'image/png'].includes(file.type)) {
		alert('Неверный тип файла. Пожалуйста, выберите JPEG или PNG.');
		return;
	}

	form.value.avatar = file;
}
function handleSubmit() {
	const formData = new FormData();
	formData.append('name', form.value.name);
	formData.append('rating', form.value.rating);
	formData.append('text', form.value.text);
	if (form.value.avatar) {
		formData.append('avatar', form.value.avatar);
	}
	console.log("FILE", formData)
	sendForm('/api/review/', formData)
		.then(() => {

			modalMessage.value = 'Спасибо за оставленный отзыв! \n' +
				'Мы стремимся улучшать качество нашей работы и доводить его до совершенства. ' +
				'Благодаря Вам мы стали чуточку лучше!';
			modalVisible.value = true;
			form.value = {
				name: '',
				rating: 0,
				text: '',
				avatar: null
			};
			setTimeout(() => {
				modalVisible.value = false;
				isFormVisible.value = false;
			}, 3000);
		})
		.catch(error => {
			console.error('Ошибка при отправке формы:', error);
			modalMessage.value = 'Произошла ошибка при отправке формы.';
			modalVisible.value = true;
		});
}
function closeForm() {
	isFormVisible.value = false;
}

function openForm() {
	isFormVisible.value = true;
}


</script>

<template>
<button @click="openForm" class="button-review">Оставить отзыв</button>
<Modal :isForm="isFormVisible" @close="closeForm" @update:isForm="isFormVisible = $event">
	<form @submit.prevent="handleSubmit" class="review-form">
		<p>Оставить отзыв</p>
		<div class="review-form__top">
			<label for="avatar" class="review-form__upload">
				<span>Фото</span>
				<input id="avatar" type="file" @change="handleFileUpload" accept=".jpeg, .jpg, .png" >
			</label>
			<div class="review-form__top__data">
				<input id="name" v-model="form.name" required placeholder="Ваше ФИО">
				<UIStarRating v-model:rating="form.rating"/>
			</div>
		</div>
		<div class="review-form__bottom">
			<div>
				<textarea id="text" v-model="form.text" required placeholder="Текст комментария" rows="7" maxlength="499"></textarea>
			</div>
		</div>
		<button type="submit">Отправить</button>
		<div class="review-form__close-icon" @click="closeForm">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" width="24" height="24">
				<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
			</svg>
		</div>
	</form>
</Modal>
<Modal :isVisible="modalVisible" @update:isVisible="modalVisible = $event">
	<pre class="review-form__message-modal">{{ modalMessage }}</pre>
</Modal>
</template>

<style scoped lang="scss">
label {
	display: block;
	margin-bottom: 5px;
}

input, select, textarea {
	width: 100%;
	padding: 8px;
	margin-bottom: 10px;
}

button {
	font-size: 25px;
	width: 40%;

	padding: 2%;
	margin: 3% 30% 1%;
	font-family: 'Manrope', sans-serif;
	color: white;
	font-weight: 300;
	border-radius: 25px;
	background: rgba(28, 71, 27, 0.65);
	box-shadow: 7px 7px 13.9px 0px rgba(0, 0, 0, 0.40);
	border: none;
	cursor: pointer;
}

.button-review {

	width: 35%;
	height: 90px;
	flex-shrink: 0;
	color: #FFF;
	font-family: Marmelad, serif;
	font-size: 30px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	background-color: #244709;
	cursor: pointer;
	border-radius: 0;
	margin-left: 33%;
}
button:hover {
	color: #8f8f8f;
}
.review-form {
	background: rgba(100, 135, 73, 0.80);
	width: 150%;
	height: 330%;
	padding: 10%;
	display: flex;
	flex-direction: column;
	align-items: center;

	&__top {
		padding: 5% 5% 0;
		margin: 0;
		display: flex;
		flex-direction: row;
		width: 80%;
		&__data {
			max-width: 52%;
			padding-left: 5%;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
	&__bottom {
		margin: 0;
		padding: 0 5%;
		width: 80%;
	}
	&__close-icon {
		display: flex;
		position: absolute;
		top: 10%;
		right: -15%;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background: rgba(100, 135, 73, 0.80);
		color: white;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	&__upload {
		border: 2px solid #ccc;
		background: #D9D9D9;
		cursor: pointer;
		border-radius: 4px;
		width: 15rem;
		height: 15rem;
		display: flex;
		align-items: center;
		margin-bottom: 4%;
		span {
			color: rgba(0, 0, 0, 0.39);
			width: 100%;
			text-align: center;
			font-size: 32.962px;
		}
	}
	&__message-modal {
		font-family: 'Manrope', sans-serif;
		white-space: pre-wrap;
		font-size: 25px;
		font-weight: 300;
		text-align: center;
		line-height: normal;
		color: white;
		width: 100%;
	}

}
#avatar {
	opacity: 0;
	width: 0;
	height: 0;
	position: absolute;
	top: 0;
	left: 0;
}
p {
	color: #FFF;
	padding: 0;
	margin: 0;
	text-align: center;
	font-family: Manrope serif;
	font-size: 33.036px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

input {
	width: 100%;
	font-family: 'Marmelad', sans-serif;
	margin-bottom: 10%;
	background-color: rgba(100, 135, 73, 0.6);
	border-radius: 0;
	font-size: 25px;
	padding: 5% 2%;
	font-weight: 300;
	border: 2px solid rgba(255, 255, 255, 0.39);
}

textarea {
	width: 90%;
	background-color: rgba(100, 135, 73, 0.6);
	padding: 0;
	margin-bottom: 12px;
	font-size: 25px;
	font-weight: 300;
	border: 2px solid rgba(255, 255, 255, 0.39);
	resize: vertical;

}

input::placeholder, textarea::placeholder {
	color: rgba(255, 255, 255, 0.39);
	text-align: left;
	font-family: 'Marmelad', sans-serif;
	font-size: 27.6px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
@media (max-width: 767px) {
	label {
		display: block;
		margin-bottom: 5px;
	}

	input, select, textarea {
		width: 100%;
		padding: 8px;
		margin-bottom: 10px;
		font-size: 20px;
	}

	button {
		font-size: 20px;
		width: 80%;
		padding: 2%;
		margin: 3% 30% 1%;
		font-family: 'Manrope', sans-serif;
		color: white;
		font-weight: 300;
		border-radius: 25px;
		background: rgba(28, 71, 27, 0.65);
		box-shadow: 7px 7px 13.9px 0px rgba(0, 0, 0, 0.40);
		border: none;
		cursor: pointer;
	}

	.button-review {
		width: 74%;
		margin: 5% 13%;
	}

	.review-form {
		background: rgba(100, 135, 73, 0.80);
		width: 90%;
		height: 200%;
		padding: 5%;
		display: flex;
		flex-direction: column;
		align-items: center;

		&__top {
			padding: 5% 5% 0;
			margin: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			&__data {
				max-width: 100%;
				padding: 5%;
				margin: 0;
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
		&__bottom {
			margin: 0;
			padding: 0;
			width: 95%;
		}

		&__upload {
			width: 10rem;
			height: 10rem;
			display: flex;
			align-items: center;
			margin-bottom: 4%;
			span {
				width: 95%;
				font-size: 25px;
			}
		}
		&__message-modal {
			font-family: 'Manrope', sans-serif;
			white-space: pre-wrap;
			font-size: 20px;
			font-weight: 300;
			text-align: center;
			line-height: normal;
			color: white;
			width: 100%;
		}
		&__close-icon {
			top: 3%;
			right: 5%;
		}

	}
	#avatar {
		opacity: 0;
		width: 0;
		height: 0;
		position: absolute;
		top: 0;
		left: 0;
	}
	p {
		font-size: 25px;
	}

	input {
		font-size: 20px;
		width: 100%;
	}

	textarea {
		width: 100%;
		font-size: 20px;
		padding: 0;
		margin: 0;


	}

	input::placeholder, textarea::placeholder {
		font-size: 20px;
	}
}
@media (min-width: 768px) and (max-width: 1024px) {
	.button-review {
		height: 90px;
		width: 74%;
		margin: 5% 13%;

	}
	.review-form {
		background: rgba(100, 135, 73, 0.80);
		width: 90%;
		height: 90%;
		padding: 2%;
		margin: 2%;
		&__bottom {
			margin-left: 10%;
			padding: 0;
			width: 90%;
		}
		&__close-icon {
			top: 8%;
			right: 8%;
		}
	}
}
</style>