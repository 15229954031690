<script setup>
import Modal from '/src/components/UI/UIModal.vue';
import { sendForm } from '/src/services/apiService';
import { cleanPhoneNumber } from '/src/utils/cleaningPhone';
import {ref} from 'vue';

const isFormVisible = ref(false);
const modalVisible = ref(false);
const modalMessage = ref('');
import {vMaska} from "maska";

const props = defineProps({
	formContext: String
});

const formData = ref({
	name: '',
	email: '',
	phone_number: '',
	amount: null,
	selectedService: '',
	message: '',
	consent: false
});



const handleSubmit = async () => {
	try {
		const formDataCopy = { ...formData.value };
		formDataCopy.phone_number = formDataCopy.phone_number ? cleanPhoneNumber(formDataCopy.phone_number) : '';
		// Формирование сообщения
		formDataCopy.message = `Клиент выбрал услугу: ${formDataCopy.selectedService}`;

		await sendForm('/api/support/', formDataCopy);
		modalMessage.value = 'Спасибо за обращение! \n' +
			'Ваше обращение успешно отправлено, наш специалист\n' +
			' с Вами свяжется\n' +
			' в ближайшее время!';
		formData.value = {
			name: '',
			email: '',
			phone_number: '',
			amount: null,
			selectedService: '',
			message: '',
			consent: false
		};
		modalVisible.value = true;
		setTimeout(() => {
			modalVisible.value = false;
			isFormVisible.value = false;
		}, 3000);
	} catch (error) {
		console.error('Ошибка при отправке формы:', error);
		modalMessage.value = 'Произошла ошибка при отправке формы.';
		modalVisible.value = true;
	}
};

function closeForm() {
	isFormVisible.value = false;
}

function openForm() {
	isFormVisible.value = true;
}
</script>

<template>
<button @click="openForm" class="button-ticket">Оставить заявку</button>
<Modal :isForm="isFormVisible" @close="closeForm" @update:isForm="isFormVisible = $event">
<form class='ticket-form' @submit.prevent="handleSubmit">
	<h1>
		Получить консультацию
		бесплатно
	</h1>

	<div class="ticket-form__input">
		<input
			id="fullName"
			v-model="formData.name"
			:placeholder="shortTicket ? 'Ваше имя' : 'Имя*'"
			type="text" required
		>

		<input
			v-maska
			data-maska="+7 (9##) ###-##-##"
			id="number"
			v-model="formData.phone_number"
			:placeholder="shortTicket ? 'Ваш телефон' : 'Телефон'"
			type="tel"

		>
		<input
			id="email"
			v-model="formData.email"
			type="email"
			placeholder="Email*"
			required
		>

		<select v-model="formData.selectedService">
			<option disabled value="">Укажите услугу</option>
			<option value="Кадастровые">Кадастровые</option>
			<option value="Юридические">Юридические</option>
			<option value="Оценочные">Оценочные</option>
			<option value="Другое">Другое</option>
		</select>
	</div>
	<div  class="ticket-form__personal-data">
		<input :id="`consent_support_${props.formContext}`" v-model="formData.consent" type="checkbox" required>
		<label :for="`consent_support_${props.formContext}`">Даю согласие на обработку персональных данных и я ознакомился с
			<a href="/docs/согласие_о_персональных_данных.docx" target="_blank">Политикой конфиденциальности</a></label>
	</div>
	<button type="submit">Отправить заявку</button>
	<div class="ticket-form__close-icon" @click="closeForm">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" width="24" height="24">
			<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
		</svg>
	</div>
</form>
</Modal>
<Modal :isVisible="modalVisible" @update:isVisible="modalVisible = $event">
	<pre class="ticket-form__message-modal">{{ modalMessage }}</pre>
</Modal>
</template>

<style scoped lang="scss">
.ticket-form {
	width: 100%;
	padding: 1% 3% 6%;
	border: 2px solid rgba(0, 0, 0, 0.3);
	background-color: rgba(100, 135, 73, 0.6);
	font-family: 'Manrope', sans-serif;
	font-size: 25px;
	font-weight: 300;
	display: flex;
	flex-direction: column;
	align-items: center;

	h1 {
	padding: 4%;
	color: #FFFFFF;
	font-family: 'Manrope', sans-serif;
	text-align: center;
	font-size: 30px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	}
	&__input {
		display: flex;
		flex-direction: column;
		width: 70%;
		input {
			font-family: 'Marmelad', sans-serif;
			padding: 5%;
			margin-bottom: 12px;
			background-color: rgba(100, 135, 73, 0.6);
			border-radius: 0;
			font-size: 25px;
			font-weight: 300;
			border: 2px solid rgba(255, 255, 255, 0.39);
		}

		select {
			-webkit-appearance: none;
			color: rgba(255, 255, 255, 0.39);
			font-family: 'Marmelad', sans-serif;
			padding: 5%;
			margin-bottom: 12px;
			background-color: rgba(100, 135, 73, 0.6);
			border-radius: 0;
			font-size: 25px;
			font-weight: 300;
			border: 2px solid rgba(255, 255, 255, 0.39);
			cursor: pointer;
		}
		textarea {

			color: #d02e2e;
			padding: 4px 0 7px 16px;
			margin-bottom: 12px;
			font-size:25px;
			font-weight: 300;
			border: 2px solid rgba(255, 255, 255, 0.39);


		}

		input::placeholder, textarea::placeholder, select::placeholder {
			color: rgba(255, 255, 255, 0.39);
			text-align: left;
			font-family: 'Marmelad', sans-serif;
			font-size: 27.6px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}


	button {

		font-size: 25px;
		width: 50%;
		padding: 2%;
		margin-top: 2%;
		font-family: 'Manrope', sans-serif;
		color: white;
		font-weight: 300;
		border-radius: 25px;
		background: rgba(28, 71, 27, 0.65);
		box-shadow: 7px 7px 13.9px 0px rgba(0, 0, 0, 0.40);
		border: none;
		cursor: pointer;

	}
	&__close-icon {
		display: flex;
		position: absolute;
		top: 12%;
		right: 8%;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background: rgba(100, 135, 73, 0.80);
		color: white;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		fill: #FFFFFF;
	}
	&__personal-data {
		display: flex;
		align-items: center;
		width: 70%;
		color: #FFF;
		font-family: 'Manrope', sans-serif;
		font-size: 13px;
		font-style: normal;
		font-weight: 300;
		line-height: normal;
		padding: 0;
		input {
			width: 10%;
			margin: 1%;
		}
		a {
			color: white;
		}
	}
	&__message-modal {
		font-family: 'Manrope', sans-serif;
		white-space: pre-wrap;
		font-size: 25px;
		font-weight: 300;
		text-align: center;
		line-height: normal;
		color: white;
	}
}
.button-ticket {

	width: 35%;
	height: 90px;
	flex-shrink: 0;
	color: #FFF;
	font-family: Marmelad, serif;
	font-size: 30px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	background-color: #244709;
	cursor: pointer;
	border-radius: 0;
	margin-left: 33%;
	margin-top: 2%;
}
button:hover {
	color: #8f8f8f;
}
@media (max-width: 767px) {
	.ticket-form {
		width: 100%;
		padding: 5% 0;
		font-size: 20px;

		h1 {
			font-size: 20px;
		}

		input {
			font-size: 20px;
		}

		textarea {
			font-size:20px;
		}

		input::placeholder, textarea::placeholder, select::placeholder {
			font-size: 20px;
		}

		button {
			width: 60%;
			font-size: 20px;
			padding: 2%;
			margin-top: 2%;
			font-family: 'Manrope', sans-serif;
			color: white;
			font-weight: 300;
			border-radius: 25px;
			background: rgba(28, 71, 27, 0.65);
			box-shadow: 7px 7px 13.9px 0px rgba(0, 0, 0, 0.40);
			border: none;
			cursor: pointer;

		}
		&__close-icon {
			top: 3%;
			right: 3%;
		}
		&__personal-data {
			display: flex;
			align-items: center;
			width: 85%;
			color: #FFF;
			font-family: 'Manrope', sans-serif;
			font-size: 13px;
			font-style: normal;
			font-weight: 300;
			line-height: normal;
			padding: 0;
			input {
				width: 10%;
				margin: 1%;
			}

		}
		&__message-modal {
			font-family: 'Manrope', sans-serif;
			white-space: pre-wrap;
			font-size: 25px;
			font-weight: 300;
			text-align: center;
			line-height: normal;
			color: white;
		}
		select {

			font-size: 20px;

		}
	}
	.button-ticket {
		width: 70%;
		height: 90px;
		margin: auto;

	}
	button:hover {
		color: #8f8f8f;
	}

}

@media (min-width: 768px) and (max-width: 1024px) {
	.ticket-form {
		width: 100%;
		padding: 5% 0;
		font-size: 25px;
		div {
			width: 60%;
		}
		h1 {
			font-size: 30px;
		}

		input {
			font-size: 25px;

		}

		textarea {
			font-size:25px;
		}

		input::placeholder, textarea::placeholder, select::placeholder {
			font-size: 25px;
		}

		button {

			font-size: 25px;
			padding: 2%;
			margin-top: 2%;
			font-family: 'Manrope', sans-serif;
			color: white;
			font-weight: 300;
			border-radius: 25px;
			background: rgba(28, 71, 27, 0.65);
			box-shadow: 7px 7px 13.9px 0px rgba(0, 0, 0, 0.40);
			border: none;
			cursor: pointer;

		}
		&__personal-data {
			display: flex;
			align-items: center;
			width: 85%;
			color: #FFF;
			font-family: 'Manrope', sans-serif;
			font-size: 18px;
			font-style: normal;
			font-weight: 300;
			line-height: normal;
			padding: 0;
			input {
				width: 10%;
				margin: 1%;
			}

		}
		&__message-modal {
			font-family: 'Manrope', sans-serif;
			white-space: pre-wrap;
			font-size: 25px;
			font-weight: 300;
			text-align: center;
			line-height: normal;
			color: white;
		}
		&__close-icon {
			width: 40px!important;
			height: 40px;
			color: #FFFFFF;
			svg {
				fill: white;
			}
		}

	}
	.button-ticket {
		width: 70%;
		height: 90px;
		margin: auto;
	}
}
</style>