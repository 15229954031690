<script setup>
import Icon from '/src/components/UI/UIContactsIcon.vue';

const props = defineProps({
	icon: String,
	text: String,
	url: String
});
</script>

<template>
<div class="contact-item">
	<Icon :name="props.icon" />
	<a v-if="props.url" :href="props.url">{{ props.text }}</a>
	<span v-else >{{ props.text }}</span>
</div>
</template>

<style scoped lang="scss">
.contact-item {
	display: flex;
	align-items: center;
	color: #311D0A;
	text-decoration: none;
	padding: 1% 0;
	font-family:  Marmelad, serif;
	font-size: 25px;
	font-style: normal;
	line-height: 35px;

	a, span {
		color: #311D0A;
		text-decoration: none;
		padding-left: 3%;
		font-family:  Marmelad, serif;
		font-size: 25px;
		font-style: normal;
		line-height: 35px;

	}

}

.contact-item Icon {
	margin-right: 20px;
}

@media (max-width: 767px) {
	.contact-item {
		width: 100%;
		font-size: 20px;
		a, span {
			font-size: 20px;
		}
	}
	.contact-item Icon {
		margin-right: 20px;
	}
}
</style>