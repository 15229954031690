<script setup>
import UIForm from '/src/components/UI/UIForm.vue';
import { ref } from 'vue';

const listItems = ref([
	{ title: 'Оформление', icon: '/img/icons/list_1.png' },
	{ title: 'Оценка недвижимости', icon: '/img/icons/list_1.png' },
	{ title: 'Межевание', icon: '/img/icons/list_2.png' },
	{ title: 'Судебные споры', icon: '/img/icons/list_2.png' },
	{ title: 'Тех. документация', icon: '/img/icons/list_3.png' },
	{ title: 'Акт обследования', icon: '/img/icons/list_3.png' }
]);
</script>

<template>
<div class="main_section" id="home">
	<h1>Кадастровый инженер в Волгограде</h1>
	<div class="main_section__subtitle">
		Оформление объектов капитального строительства и участков
	</div>
	<div class="main_section__list">
		<ul>
			<li v-for="(item, index) in listItems" :key="index">
				<img :src="item.icon" alt="" class="icon"/>
				{{ item.title }}
			</li>
		</ul>
		<div class="main_section__form">
			<UIForm shortTicket apiEndpoint="/api/support/" formContext="page"/>
	</div>
	</div>
</div>
</template>

<style lang="scss" scoped>
.main_section {
	background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url("@/assets/img/MainSection/main-section-background.png");
	width: 100%;
	background-size: cover;
	padding-bottom: 5%;
	margin: 0;
	h1 {
		color: #FFF;
		padding: 10.6% 3% 0.3%;
		text-align: left;
		margin: 0;
		font-size: 60px;
		font-style: normal;
		font-weight: 400;
	}
	&__subtitle {
		font-size: 30px;
		padding-left: 3%;
		margin: 0;

	}

	img {
		width: 100%;

	}

	&__list {
		display: flex;
		align-items: center;

		ul {
			width: 70%;
			padding: 5%;
			display: flex;
			align-items: center;
			flex-direction: row;
			flex-wrap: wrap;

		}
		li {
			list-style-type: none;
			width: 44%;
			font-size: 25px;
			white-space: nowrap;
			padding: 3%;
			display: flex;
			flex-direction: row;
			align-items: center;

		}
		img {
			width: 33px;
			height: 33px;
			margin-right: 10px;
			padding: 0;

		}
	}
	&__form {
		width: 64%;
		padding: 5%;
	}
}
@media (max-width: 767px) {
	.main_section {
		display: flex;
		flex-direction: column;

		h1 {

			font-size: 32px;
			font-style: normal;
			font-weight: 400;
		}

		&__subtitle {
			font-size: 20px;
			padding: 5%;
		}


		&__list {
			display: flex;
			flex-direction: column;
			align-items: center;

			ul {
				width: 100%;
				padding: 0;
				margin: 0;
				display: flex;
				align-items: center;
				flex-direction: column;
				flex-wrap: wrap;

			}

			li {
				list-style-type: none;
				width: 96%;
				font-size: 20px;
				white-space: nowrap;
				padding: 2% 2%;
				display: flex;
				flex-direction: row;
				align-items: center;

			}

			img {
				width: 20px;
				height: 20px;
				margin-right: 10px;
				padding: 0;

			}
		}

		&__form {
			width: 100%;
			padding: 0;

		}
	}
}
@media (min-width: 768px) and (max-width: 1024px) {
	.main_section {
		&__subtitle {

			padding: 5%;
		}
		&__list {

			ul {
				width: 45%;
				padding: 0;
				margin: 0;
				display: flex;
				align-items: center;
				align-content: flex-start;
				flex-direction: column;
				flex-wrap: wrap;
				justify-content: space-between;

			}


		}
		&__form {
			width: 45%;
			padding: 0;

		}
	}
}
</style>